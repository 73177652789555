import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import type {
  APIException,
  Role,
  User,
  UsersByRole,
  UsersState,
  UserState,
} from '../types'
import { ServiceProvider } from '../services/ServiceProvider'
import { RootState } from './index'
import { UsersKey } from '../services/LoadingService'
import { API } from '../services/API'

export const loadUser = createAsyncThunk(
  'users/loadUser',
  async (
    id: number,
  ): Promise<{ user?: User; exception?: APIException | null }> =>
    ServiceProvider.getServices()
      .user.single(id)
      .then((user) => ({
        user,
      }))
      .catch((exception: APIException | null) => ({
        exception,
      })),
)

export const loadUsersByRole = createAsyncThunk(
  'users/loadUsersByRole',
  async (role: Role | null): Promise<{ role: Role | null; users: User[] }> =>
    new Promise(async (resolve) => {
      let users: User[] = []
      if (API.hasToken()) {
        users = await ServiceProvider.getServices()
          .user.list({ page: 1, length: 100, ...(role ? { role: role } : {}) })
          .then((response) => response.data)
          .catch(() => [])
      }
      resolve({ role, users })
    }),
)

const slice = createSlice({
  initialState: {
    user: {
      user: null,
      loading: false,
      exception: null,
    },
    roles: [],
  } as UsersState,
  name: 'users',
  reducers: {
    setUser: (state, { payload }: PayloadAction<User | null>) => {
      state.user.user = payload
    },
    resetRoles: (state) => {
      state.roles = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadUser.pending, (state) => {
      state.user.user = null
      state.user.loading = true
      state.user.exception = null
    })
    builder.addCase(loadUser.rejected, (state) => {
      state.user.loading = false
    })
    builder.addCase(
      loadUser.fulfilled,
      (state, { payload: { user, exception } }) => {
        state.user.loading = false
        state.user.user = user ?? null
        state.user.exception = exception ?? null
      },
    )
    builder.addCase(
      loadUsersByRole.pending,
      (_state, { meta: { arg: role } }) => {
        ServiceProvider.getServices().loading.toggle(new UsersKey(role), true)
      },
    )
    builder.addCase(
      loadUsersByRole.rejected,
      (_state, { meta: { arg: role } }) => {
        ServiceProvider.getServices().loading.toggle(new UsersKey(role), false)
      },
    )
    builder.addCase(
      loadUsersByRole.fulfilled,
      (state, { payload: { role, users } }) => {
        const index = state.roles.findIndex(
          (stateRole) => stateRole.role?.slug === role?.slug,
        )
        if (index >= 0) {
          state.roles[index].users = users
        } else {
          state.roles.push({ role, users })
        }
        ServiceProvider.getServices().loading.toggle(new UsersKey(role), false)
      },
    )
  },
})

export const { resetRoles, setUser } = slice.actions

export default slice.reducer

export const getUser = (state: RootState): UserState => state.users.user

export const getUsersByRole = (state: RootState): Array<UsersByRole> | null =>
  state.users.roles
