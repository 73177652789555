import { QueryParams } from '../types'
import { useSearchParams } from 'react-router-dom'
import { useCallback } from 'react'
import { serialiseTableParams } from '../utils'
import { useSelector } from 'react-redux'
import { getQueryParams } from '../store/queryParams'

export const useQueryParams = (): {
  params: QueryParams
  updateParams: (params: QueryParams) => void
} => {
  const params = useSelector(getQueryParams)
  const setSearchParams = useSearchParams()[1]

  const updateParams = useCallback(
    (update: QueryParams) => {
      setSearchParams(serialiseTableParams({ ...params, ...update }))
    },
    [params, setSearchParams],
  )

  return { params, updateParams }
}
