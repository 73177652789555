import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { SearchBar } from '../../../horizon/components/navbar/searchBar/SearchBar'

const DebouncedSearch: React.FunctionComponent<{
  value: string
  onChange: (value: string) => void
  debounce?: number
  searchBarProps?: any
}> = ({
  value: initialValue,
  onChange,
  debounce = 500,
  searchBarProps = {},
}) => {
  const [value, setValue] = useState(initialValue)
  const valueRef = useRef(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (value !== valueRef.current) {
        valueRef.current = value
        onChange(value)
      }
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value, onChange, debounce])

  return (
    <SearchBar
      {...searchBarProps}
      value={value}
      onChange={(e: any) => setValue(e.target.value)}
      height="44px"
      width={{ lg: '390px' }}
      borderRadius="16px"
    />
  )
}

export default DebouncedSearch
