import * as React from 'react'
import { Fragment, useCallback, useContext, useEffect, useState } from 'react'
import AdminTemplate from '../../templates/AdminTemplate'
import routes from '../../../../routes'
import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  Grid,
  GridItem,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import UploadCard from '../../molecules/shared/UploadCard'
import { ServiceContext } from '../../../../components/Services'
import { WarrantyImportField, WarrantyImportHeader } from '../../../../types'
import { attemptImportFieldMatch, WARRANTY_IMPORT_FIELDS } from '../../../../utils'
import WarrantyImportHeaderCard from '../../organisms/warranties/WarrantyImportHeaderCard'

const WarrantyImportPage: React.FunctionComponent = () => {
  const services = useContext(ServiceContext)
  const toast = useToast()
  const [files, setFiles] = useState<File[]>([])
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState<any>([])
  const [headers, setHeaders] = useState<WarrantyImportHeader[]>([])
  const [missing, setMissing] = useState<WarrantyImportField[]>([])

  const onParse = useCallback(() => {
    setLoading(true)
    services.warranty
      .parseImport(files[0])
      .then((rows: any[]) => {
        setLoading(false)
        if (rows.length > 0) {
          setRows(rows)
        } else {
          toast({
            title: 'Upload Failed',
            description:
              'The CSV file does not appear to contain any data, please check it and try again',
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        }
      })
      .catch(() => {
        setLoading(false)
        toast({
          title: 'Upload Failed',
          description:
            'There was a problem uploading the CSV file, please check it and try again',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }, [services, toast, files])

  const onReset = useCallback(() => {
    setRows([])
    setMissing([])
  }, [])

  const onUpdateHeader = useCallback(
    (header: WarrantyImportHeader, field: WarrantyImportField | undefined) => {
      const updated = [...headers]
      if (field) {
        const existingIndexes = updated
          .filter((header) => header.field?.name === field.name)
          .map(({ index }) => index)
        for (let index of existingIndexes) {
          updated[index].field = undefined
        }
      }
      updated[header.index].field = field
      setHeaders(updated)
    },
    [headers],
  )

  const onImport = useCallback(() => {
    const missing = WARRANTY_IMPORT_FIELDS.filter(
      (field) =>
        field.required &&
        headers.filter((header) => header.field?.name === field.name).length <
          1,
    )
    setMissing(missing)
    if (missing.length < 1) {
      const data: any[] = []
      for (let row of rows) {
        const record: any = {}
        const values = Object.values(row)
        const populatedHeaders = headers.filter(({ field }) => !!field)
        for (let header of populatedHeaders) {
          record[header.field.name] = header.field.transform
            ? header.field.transform(`${values[header.index]}`)
            : values[header.index]
        }
        data.push(record)
      }

      setLoading(true)
      services.warranty
        .storeImport(data)
        .then((updated: number) => {
          setLoading(false)
          onReset()
          toast({
            title: 'Import Complete',
            description: `${updated} record${updated === 1 ? '' : 's'} have been successfully imported`,
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        })
        .catch(() => {
          setLoading(false)
          toast({
            title: 'Import Failed',
            description:
              'There was a problem importing the data file, please check it and try again',
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        })
    }
  }, [services, toast, rows, headers, onReset])

  useEffect(() => {
    if (rows.length > 0) {
      setHeaders(
        Object.keys(rows[0]).map((key, index) => ({
          index,
          title: key,
          field: attemptImportFieldMatch(key),
        })),
      )
    } else {
      setHeaders([])
    }
  }, [rows])

  const textPrimary = useColorModeValue('secondaryGray.900', 'white')
  const textSecondary = 'secondaryGray.600'
  return (
    <AdminTemplate
      breadcrumbs={[
        { label: 'Warranties 2000', link: routes.dashboard },
        { label: 'Warranties', link: routes.warranties.index },
        { label: 'Import' },
      ]}
      title="Import Warranties"
    >
      <Grid marginBottom="20px" templateColumns="repeat(5, 1fr)" gap="20px">
        <GridItem />
        <GridItem colSpan={3}>
          <UploadCard
            prompt="Drop your CSV file here"
            accept="text/csv"
            onSelect={setFiles}
            loading={loading}
            disabled={headers.length > 0}
            footer={
              files.length < 1 ? (
                <Fragment />
              ) : (
                <Flex justifyContent="center" marginTop="1rem">
                  {headers.length < 1 ? (
                    <Button
                      variant="brand"
                      onClick={onParse}
                      isLoading={loading}
                    >
                      Upload
                    </Button>
                  ) : (
                    <Button
                      variant="outline"
                      onClick={onReset}
                      isLoading={loading}
                    >
                      Reset
                    </Button>
                  )}
                </Flex>
              )
            }
          />
        </GridItem>
        <GridItem />
        {headers.length > 1 ? (
          <>
            <GridItem colSpan={4}>
              <Text fontSize="large" color={textPrimary} fontWeight="bold">
                Found {headers.length} Column{headers.length === 1 ? '' : 's'}
              </Text>
              <Text fontSize="md" color={textSecondary}>
                Make sure the columns are correctly mapped to their
                corresponding field in the system using the dropdown fields
                below
              </Text>
            </GridItem>
            <GridItem>
              <Button variant="brand" onClick={onImport} isLoading={loading}>
                Import
              </Button>
            </GridItem>
            {missing.length > 0 ? (
              <GridItem colSpan={5}>
                <Alert status="error">
                  <AlertIcon />
                  <Text fontSize="md">
                    The following fields need to be mapped in order to complete
                    the import:
                    <br />
                    {missing.map((field, index) => (
                      <strong key={index}>
                        {field.title}
                        {index < missing.length - 1 ? ',' : ''}
                        &nbsp;
                      </strong>
                    ))}
                  </Text>
                </Alert>
              </GridItem>
            ) : null}
          </>
        ) : null}
        {headers.map((header, index) => (
          <GridItem key={index}>
            <WarrantyImportHeaderCard
              header={header}
              onUpdate={onUpdateHeader}
              disabled={loading}
            />
          </GridItem>
        ))}
      </Grid>
    </AdminTemplate>
  )
}

export default WarrantyImportPage
