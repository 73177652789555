import React, { useCallback, useContext, useState } from 'react'
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import routes from '../../../routes'
import { NavLink } from 'react-router-dom'
import { BiArrowBack } from 'react-icons/bi'
import { ServiceContext } from '../../../components/Services'
import AccessTemplate from '../templates/AccessTemplate'

type Inputs = {
  email: string
}

const ForgotPassword: React.FunctionComponent = () => {
  const services = useContext(ServiceContext)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()
  const textColor = useColorModeValue('purple.900', 'white')
  const textColorSecondary = 'gray.400'
  const textColorBrand = useColorModeValue('brand.900', 'white')

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [submitted, setSubmitted] = useState(false)

  const onSubmit = useCallback(
    (input: Inputs) => {
      setError('')
      setLoading(true)
      services.auth
        .forgotPassword(
          input.email,
          `${window.location.origin}${routes.resetPassword}?token={TOKEN}`,
        )
        .then(() => {
          setLoading(false)
          setSubmitted(true)
        })
        .catch(() => {
          setLoading(false)
          setError(
            'Sorry there was a problem resetting your password, please get in touch with our team',
          )
        })
    },
    [services],
  )

  return (
    <AccessTemplate>
      <Box me="auto">
        <Heading color={textColor} fontSize="36px" mb="10px">
          Forgot Password
        </Heading>
        <Text
          marginBottom="1rem"
          color={textColorSecondary}
          fontWeight="400"
          fontSize="md"
        >
          {!submitted
            ? "Enter your email below and if you have an account we'll send you a link to reset your password"
            : 'Please check your inbox for an email from us to reset your password'}
        </Text>
        <Link
          as={NavLink}
          to={routes.login}
          alignItems="center"
          display="flex"
          gap="0.5rem"
          marginY="1rem"
        >
          <BiArrowBack />
          <Text as="span" color={textColorBrand} fontSize="sm" fontWeight="500">
            Return to login
          </Text>
        </Link>
      </Box>
      {!submitted ? (
        <Flex
          marginX={{ base: 'auto', lg: '0px' }}
          flexDirection="column"
          justifyContent="center"
          paddingX={{ base: '20px', md: '0px' }}
          width="100%"
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate={true}
        >
          <Flex
            direction="column"
            marginX={{ base: 'auto', lg: 'unset' }}
            marginBottom={{ base: '20px', md: 'auto' }}
            width="100%"
          >
            {error ? (
              <Alert status="error">
                <AlertIcon />
                {error}
              </Alert>
            ) : null}
            <FormControl
              marginY="0.5rem"
              isInvalid={!!errors.email?.type}
              isDisabled={loading}
            >
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Email *
              </FormLabel>
              <Input
                type="email"
                fontSize="sm"
                fontWeight="500"
                size="lg"
                color={textColor}
                {...register('email', { required: true })}
              />
              {errors.email?.type === 'required' ? (
                <FormErrorMessage>
                  Please enter an email address
                </FormErrorMessage>
              ) : null}
            </FormControl>
            <Button
              type="submit"
              variant="brand"
              isDisabled={loading}
              fontSize="sm"
              fontWeight="500"
              marginBottom="1rem"
              marginTop="1.5rem"
              paddingY="1.5rem"
              width="100%"
            >
              Submit
            </Button>
          </Flex>
        </Flex>
      ) : null}
    </AccessTemplate>
  )
}

export default ForgotPassword
