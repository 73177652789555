import React from 'react'
import { Flex, FormControl, FormLabel, Switch } from '@chakra-ui/react'

export type SwitchOption = {
  title: string
  value: string
}

const SwitchSelector: React.FunctionComponent<{
  options: SwitchOption[]
  selected: SwitchOption | undefined
  onSelect: (selected: SwitchOption | undefined) => void
  isDisabled?: boolean
}> = ({ options, selected, onSelect, isDisabled = false }) => {
  return (
    <Flex direction="column" gap="0.5rem" paddingX="10px">
      {options.map((option, index) => (
        <FormControl
          key={index}
          display="flex"
          alignItems="center"
          isDisabled={isDisabled}
        >
          <Switch
            id={option.value}
            colorScheme="brand"
            isChecked={option.value === selected?.value}
            onChange={() => onSelect(option)}
          />
          <FormLabel htmlFor={option.value} margin={0} marginLeft="1rem">
            {option.title}
          </FormLabel>
        </FormControl>
      ))}
    </Flex>
  )
}

export default SwitchSelector
