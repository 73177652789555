import {
  Flex,
  Link,
  List,
  ListItem,
  Text,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'
import React from 'react'

const Footer: React.FunctionComponent = () => {
  const textColor = useColorModeValue('gray.400', 'white')
  const { colorMode, toggleColorMode } = useColorMode()
  return (
    <Flex
      w={{ base: '100%', xl: '1170px' }}
      maxW={{ base: '90%', xl: '1170px' }}
      zIndex="1.5"
      flexDirection={{
        base: 'column',
        xl: 'row',
      }}
      alignItems={{
        base: 'center',
        xl: 'start',
      }}
      justifyContent="space-between"
      px={{ base: '0px', xl: '0px' }}
      pb="30px"
      mx="auto"
    >
      <Text
        color={textColor}
        textAlign={{
          base: 'center',
          xl: 'start',
        }}
        mb={{ base: '20px', xl: '0px' }}
      >
        {' '}
        &copy; {new Date().getFullYear()}
        <Text as="span" fontWeight="500" ms="4px">
          Warranties 2000. All Rights Reserved.
        </Text>
      </Text>
      <List display="flex" gap="2rem">
        <ListItem>
          <Link
            fontWeight="500"
            color={textColor}
            href="#"
            onClick={() => toggleColorMode()}
          >
            Switch to {colorMode === 'light' ? 'Dark' : 'Light'} Mode
          </Link>
        </ListItem>
        <ListItem>
          <Link
            fontWeight="500"
            color={textColor}
            href="https://warranties2000.co.uk/privacy-policy/"
            target="_blank"
          >
            Privacy Policy
          </Link>
        </ListItem>
      </List>
    </Flex>
  )
}

export default Footer
