import { NavLink, useLocation } from 'react-router-dom'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  Icon,
  List,
  ListItem,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { FaCircle } from 'react-icons/fa'

const Links = (props: { routes: RoutesType[]; [x: string]: any }) => {
  let location = useLocation()
  let activeColor = useColorModeValue('gray.700', 'white')
  let activeIcon = useColorModeValue('brand.500', 'white')

  const { routes } = props

  const activeRoute = (routeName: string) => {
    return routeName === '/'
      ? location.pathname === '/'
      : location.pathname.includes(routeName)
  }

  const createLinks = (routes: RoutesType[]) => {
    return routes.map((route, key) => {
      if (route.collapse) {
        return (
          <Accordion
            defaultIndex={activeRoute(route.path.toLowerCase()) ? 0 : 1}
            allowToggle
            key={key}
          >
            <AccordionItem maxW="100%" border="none" key={key}>
              <AccordionButton
                display="flex"
                alignItems="center"
                justifyContent="center"
                _hover={{
                  bg: 'unset',
                }}
                _focus={{
                  boxShadow: 'none',
                }}
                borderRadius="8px"
                w={{
                  sm: '100%',
                  xl: '100%',
                  '2xl': '95%',
                }}
                px="0"
                py="0px"
                bg="transparent"
                ms={0}
              >
                {route.icon ? (
                  <Flex align="center" justifyContent="space-between" w="100%">
                    <HStack
                      mb="6px"
                      spacing={
                        activeRoute(route.path.toLowerCase()) ? '22px' : '26px'
                      }
                    >
                      <Flex
                        w="100%"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Box color={activeIcon} me="12px" mt="6px">
                          {route.icon}
                        </Box>
                        <Text
                          display="block"
                          me="auto"
                          color={
                            activeRoute(route.path.toLowerCase())
                              ? activeColor
                              : 'secondaryGray.600'
                          }
                          fontWeight="500"
                          fontSize="md"
                        >
                          {route.name}
                        </Text>
                      </Flex>
                    </HStack>
                    <AccordionIcon
                      display="block"
                      ms="auto"
                      color={'secondaryGray.600'}
                      transform={route.icon ? null : 'translateX(-70%)'}
                    />
                  </Flex>
                ) : (
                  <Flex
                    pt="0px"
                    pb="10px"
                    justify={'center'}
                    alignItems="center"
                    w="100%"
                  >
                    <HStack
                      spacing={
                        activeRoute(route.path.toLowerCase()) ? '22px' : '26px'
                      }
                      ps="34px"
                    >
                      <Text
                        me="auto"
                        color={activeColor}
                        fontWeight="500"
                        fontSize="sm"
                      >
                        {route.name}
                      </Text>
                    </HStack>
                    <AccordionIcon
                      display="block"
                      ms="auto"
                      color={'secondaryGray.600'}
                      transform={null}
                    />
                  </Flex>
                )}
              </AccordionButton>
              <AccordionPanel
                display="block"
                justifyContent="center"
                alignItems="center"
                flexDirection={'column'}
                paddingX="0"
                paddingY="0"
              >
                <List>
                  {
                    route.icon
                      ? createLinks(route.items) // for bullet accordion links
                      : createAccordionLinks(route.items) // for non-bullet accordion links
                  }
                </List>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        )
      } else {
        return (
          <NavLink to={route.path} key={key}>
            {route.icon ? (
              <Flex
                align="center"
                justifyContent="space-between"
                w="100%"
                mb="0px"
              >
                <HStack
                  mb="6px"
                  spacing={
                    activeRoute(route.path.toLowerCase()) ? '22px' : '26px'
                  }
                >
                  <Flex w="100%" alignItems="center" justifyContent="center">
                    <Box color={activeIcon} me="12px" mt="6px">
                      {route.icon}
                    </Box>
                    <Text
                      me="auto"
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeColor
                          : 'secondaryGray.600'
                      }
                      fontWeight="500"
                    >
                      {route.name}
                    </Text>
                  </Flex>
                </HStack>
              </Flex>
            ) : (
              <ListItem ms={null}>
                <Flex ps="34px" alignItems="center" mb="8px">
                  <Text color={activeColor} fontWeight="500" fontSize="sm">
                    {route.name}
                  </Text>
                </Flex>
              </ListItem>
            )}
          </NavLink>
        )
      }
    })
  }

  const createAccordionLinks = (routes: RoutesType[]) => {
    return routes.map((route: RoutesType, key: number) => {
      return (
        <NavLink to={route.path} key={key}>
          <ListItem
            ms="28px"
            display="flex"
            alignItems="center"
            mb="10px"
            key={key}
          >
            <Icon w="6px" h="6px" me="8px" as={FaCircle} color={activeIcon} />
            <Text
              color={activeColor}
              fontWeight={
                activeRoute(route.path.toLowerCase()) ? 'bold' : 'normal'
              }
              fontSize="sm"
            >
              route.name
            </Text>
          </ListItem>
        </NavLink>
      )
    })
  }
  return <>{createLinks(routes)}</>
}

export default Links
