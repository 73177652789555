import React from 'react'
import Card from '../../../../horizon/components/card/Card'
import { Text } from '@chakra-ui/react'
import { DATE_FORMAT } from '../../../../utils'
import { format } from 'date-fns'

const DetailHeader: React.FunctionComponent<{
  title: string
  subtitle?: string
  createdAt?: Date
  updatedAt?: Date
}> = ({ title, subtitle, createdAt, updatedAt }) => {
  return (
    <Card justifySelf="center" alignSelf="center" padding="24px">
      <Card
        bgGradient="linear(to-b, brand.400, brand.600)"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="10%"
        flexDirection="column"
        padding={{ base: '50px 20px', md: '50px' }}
        borderRadius="20px"
      >
        <Text
          lineHeight="100%"
          fontSize={{ sm: 'lg', md: '30px', lg: '36px', xl: '40px' }}
          fontWeight="bold"
          color="white"
          marginBottom="0.5rem"
        >
          {title}
        </Text>
        {subtitle ? (
          <Text
            fontSize={{ base: 'md', md: 'xl' }}
            fontWeight="regular"
            color="white"
          >
            {subtitle}
          </Text>
        ) : null}
        {createdAt ? (
          <Text
            fontSize={{ base: 'md', md: 'xl' }}
            fontWeight="regular"
            color="white"
          >
            Date Added: {format(createdAt, DATE_FORMAT)}
          </Text>
        ) : null}
        {updatedAt ? (
          <Text
            fontSize={{ base: 'md', md: 'xl' }}
            fontWeight="regular"
            color="white"
          >
            Last Updated: {format(updatedAt, DATE_FORMAT)}
          </Text>
        ) : null}
      </Card>
    </Card>
  )
}

export default DetailHeader
