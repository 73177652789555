import { FormConfig, FormFieldType } from '../types'

export type ProductDetailsFields = {
  name: string
}

export const productDetails: FormConfig = {
  fields: [
    {
      name: 'name',
      label: 'Name',
      requiredError: 'Please enter a name for this product',
      type: FormFieldType.Text,
    },
  ],
}

export const tariffAdminDetails: FormConfig = {
  fields: [
    {
      name: 'duration',
      label: 'Duration',
      requiredError: 'Please enter a duration',
      type: FormFieldType.Text,
      rightAddon: 'months',
    },
    {
      name: 'plan',
      label: 'Payment Plan',
      requiredError: 'Please select a payment plan',
      type: FormFieldType.Select,
    },
    {
      name: 'claimLimit',
      label: 'Claim Limit',
      requiredError: 'Please enter a claim limit',
      type: FormFieldType.Text,
      leftAddon: '£',
    },
    {
      name: 'adminFee',
      label: 'Admin Fee',
      requiredError: 'Please enter an admin fee',
      type: FormFieldType.Text,
      leftAddon: '£',
    },
    {
      name: 'premium',
      label: 'Premium',
      requiredError: 'Please enter a premium',
      type: FormFieldType.Text,
      leftAddon: '£',
    },
  ],
}

export const tariffValueDetails: FormConfig = {
  fields: [
    {
      name: 'netPrice',
      label: 'Net Price',
      requiredError: 'Please enter a net price',
      type: FormFieldType.Text,
      leftAddon: '£',
    },
    {
      name: 'vat',
      label: 'VAT',
      requiredError: 'Please enter the VAT amount',
      type: FormFieldType.Text,
      leftAddon: '£',
    },
    {
      name: 'grossPrice',
      label: 'Gross Price',
      requiredError: 'Please enter a gross price',
      type: FormFieldType.Text,
      leftAddon: '£',
    },
    {
      name: 'initial',
      label: 'Deposit / Initial Payment',
      requiredError: 'Please enter a deposit / initial payment amount',
      type: FormFieldType.Text,
      leftAddon: '£',
    },
  ],
}
