import React, { useCallback, useContext, useState } from 'react'
import { useToast } from '@chakra-ui/react'
import {
  AvailableServices,
  ServiceContext,
} from '../../../../components/Services'
import FormCard from '../../molecules/shared/FormCard'
import { useUser } from '../../../../hooks'
import GridForm from '../forms/GridForm'
import { changeUserPassword, ChangeUserPasswordFields } from '../../../../forms'

const UserPassword: React.FunctionComponent = () => {
  const services: AvailableServices = useContext(ServiceContext)
  const toast = useToast()
  const { user, onUpdate } = useUser()

  const [show, setShow] = useState(false)

  const renderFields = useCallback(
    (disabled: boolean, readOnly: boolean) => (
      <GridForm
        config={changeUserPassword}
        isDisabled={disabled}
        isReadOnly={readOnly}
        showPasswords={show}
        onTogglePasswords={setShow}
      />
    ),
    [show],
  )

  const onSubmit = useCallback(
    (
      input: ChangeUserPasswordFields,
      setError: (error: string) => void,
      onLoading: (loading: boolean) => void,
      onSaved: () => void,
    ) => {
      setError('')
      onLoading(true)
      services.user
        .changePassword({
          id: user.id,
          password: input.password,
        })
        .then((updated) => {
          onLoading(false)
          onUpdate(updated)
          onSaved()
          toast({
            title: 'Password Updated',
            description: `You have successfully changed ${user.full_name}'s password`,
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        })
        .catch(() => {
          onLoading(false)
          setError(
            'Sorry, there was a problem saving the password you selected',
          )
        })
    },
    [services, toast, user, onUpdate],
  )

  return (
    <FormCard<ChangeUserPasswordFields>
      title="Change Password"
      subtitle="Here you can change the user's password"
      renderFields={renderFields}
      onSubmit={onSubmit}
      editLabel="Change"
      saveLabel="Save"
      fill={false}
    />
  )
}

export default UserPassword
