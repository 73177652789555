import {
  Product,
  ProductResponse,
  ProductTariff,
  ProductTariffResponse,
  ProductTariffUpdate,
  ProductTariffUpdateData,
  ProductUpdate,
  ProductUpdateData,
} from '../types'

export const mapProduct = (data: ProductResponse): Product => {
  return {
    ...data,
    tariffs:
      data.tariffs?.length > 0 ? data.tariffs.map(mapProductTariff) : undefined,
    created_at: new Date(data.created_at),
    updated_at: new Date(data.updated_at),
  }
}

export const mapProductUpdate = (data: ProductUpdate): ProductUpdateData => {
  return {
    ...data,
  }
}

export const mapProductTariff = (
  data: ProductTariffResponse,
): ProductTariff => {
  const { created_at, updated_at, product, ...attrs } = data
  return {
    ...attrs,
    product: product ? mapProduct(product) : undefined,
    created_at: new Date(created_at),
    updated_at: new Date(updated_at),
  }
}

export const mapProductTariffUpdate = (
  data: ProductTariffUpdate,
): ProductTariffUpdateData => {
  const { claimLimit, grossPrice, netPrice, adminFee, ...attrs } = data
  return {
    ...attrs,
    claim_limit: claimLimit,
    gross_price: grossPrice,
    net_price: netPrice,
    admin_fee: adminFee,
  }
}
