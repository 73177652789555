import type {
  Garage,
  GarageResponse,
  GarageUpdate,
  GarageUpdateData,
} from '../types'

export const mapGarage = (data: GarageResponse): Garage => {
  return {
    ...data,
    created_at: new Date(data.created_at),
    updated_at: new Date(data.updated_at),
  }
}

export const mapGarageUpdate = (data: GarageUpdate): GarageUpdateData => {
  return {
    ...data,
  }
}
