import * as React from 'react'
import AdminTemplate from '../../templates/AdminTemplate'
import routes from '../../../../routes'
import ProductsTable from '../../organisms/products/ProductsTable'
import { useProductTableData } from '../../../../hooks'

const ProductsPage: React.FunctionComponent = () => {
  const { products, loadingProducts } = useProductTableData()

  return (
    <AdminTemplate
      breadcrumbs={[
        { label: 'Warranties 2000', link: routes.dashboard },
        { label: 'Administration' },
        { label: 'Products' },
      ]}
      title="Products"
    >
      <ProductsTable data={products} loading={loadingProducts} />
    </AdminTemplate>
  )
}

export default ProductsPage
