import * as React from 'react'
import AdminTemplate from '../../templates/AdminTemplate'
import routes from '../../../../routes'

const ProfilePage: React.FunctionComponent = () => {
  return (
    <AdminTemplate
      breadcrumbs={[
        { label: 'Warranties 2000', link: routes.dashboard },
        { label: 'Profile' },
      ]}
      title="Profile"
    />
  )
}

export default ProfilePage
