import React from 'react'
import { Button, Flex, Icon } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'
import { IconType } from 'react-icons'

export type TableButton = {
  title: string
  icon: IconType
  route?: string
  onClick?: () => void
}

const TableButtons: React.FunctionComponent<{ buttons: TableButton[] }> = ({
  buttons,
}) =>
  buttons.length > 0 ? (
    <Flex flex={1} gap="0.5rem" justifyContent="flex-end">
      {buttons.map(({ title, icon, route, onClick }, index) => (
        <Button
          key={index}
          as={route ? NavLink : 'button'}
          {...(route ? { to: route } : {})}
          {...(onClick ? { onClick } : {})}
          leftIcon={
            <Icon color="secondaryGray.500" as={icon} w="20px" h="20px" />
          }
          variant="brand"
        >
          {title}
        </Button>
      ))}
    </Flex>
  ) : null

export default TableButtons
