import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  Alert,
  AlertIcon,
  Flex,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import Card from '../../../../horizon/components/card/Card'
import {
  AvailableServices,
  ServiceContext,
} from '../../../../components/Services'
import { ROLES } from '../../../../utils'
import { useSelector } from 'react-redux'
import { getUser } from '../../../../store/auth'
import { useUser } from '../../../../hooks'
import SwitchSelector, {
  SwitchOption,
} from '../../molecules/shared/SwitchSelector'

const UserRole: React.FunctionComponent = () => {
  const services: AvailableServices = useContext(ServiceContext)
  const currentUser = useSelector(getUser)
  const options = useMemo(
    () => ROLES.map(({ name, slug }) => ({ title: name, value: slug })),
    [],
  )
  const { user, onUpdate } = useUser()
  const [role, setRole] = useState<SwitchOption | undefined>(undefined)

  useEffect(() => {
    setRole(options.find(({ value }) => value === user.role.slug))
  }, [user, options])

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const onSelect = useCallback(
    (selected: SwitchOption) => {
      if (role?.value !== selected?.value) {
        setRole(selected)
        setError('')
        setLoading(true)
        services.user
          .update(user.id, {
            role: selected.value,
          })
          .then((updated) => {
            setLoading(false)
            onUpdate(updated)
          })
          .catch(() => {
            setLoading(false)
            setError("Sorry, there was a problem changing this user's role")
          })
      }
    },
    [services, role, user, onUpdate],
  )

  const textPrimary = useColorModeValue('secondaryGray.900', 'white')
  const textSecondary = 'secondaryGray.600'
  return (
    <Card>
      <Flex direction="column" paddingBottom="1rem">
        <Text fontSize="xl" color={textPrimary} fontWeight="bold">
          User Role
        </Text>
        <Text fontSize="md" color={textSecondary}>
          {user.id !== currentUser.id
            ? "Here you can change the user's role which determines what they can access within the system"
            : "You can't change your own role as this could result in you locking yourself out"}
        </Text>
      </Flex>
      {error ? (
        <Alert status="error">
          <AlertIcon />
          {error}
        </Alert>
      ) : null}
      <SwitchSelector
        options={options}
        selected={role}
        onSelect={onSelect}
        isDisabled={loading || user.id === currentUser.id}
      />
    </Card>
  )
}

export default UserRole
