import { MdAdd, MdEdit, MdUnarchive } from 'react-icons/md'
import * as React from 'react'
import { useCallback, useContext, useMemo, useState } from 'react'

import { createColumnHelper } from '@tanstack/react-table'
import type { PaginatedResponse, PaginationMeta, User } from '../../../../types'
import routes from '../../../../routes'
import { reverse } from 'named-urls'
import DataTable from '../../molecules/shared/DataTable'
import Actions from '../../molecules/shared/Actions'
import DataTableHeader from '../../molecules/shared/DataTableHeader'
import DataTableCell from '../../molecules/shared/DataTableCell'
import TableButtons from '../../molecules/shared/TableButtons'
import {
  AvailableServices,
  ServiceContext,
} from '../../../../components/Services'
import { useNavigate } from 'react-router-dom'

type UserRow = {
  id: number
  name: string
  email: string
  role: string
}

const UsersTable: React.FunctionComponent<{
  data: PaginatedResponse<User, PaginationMeta>
  loading: boolean
  archive?: boolean
}> = ({ data, loading, archive = false }) => {
  const services = useContext<AvailableServices>(ServiceContext)
  const [restoring, setRestoring] = useState(false)
  const navigate = useNavigate()
  const rows = useMemo<UserRow[]>(
    () =>
      (data?.data ?? []).map((user: User) => ({
        id: user.id,
        name: user.full_name,
        email: user.email,
        role: user.role.name,
      })),
    [data],
  )

  const handleRestore = useCallback(
    (id: number) => {
      setRestoring(true)
      services.user
        .restore(id)
        .then(() => {
          setRestoring(false)
          navigate(reverse(routes.users.detail, { userId: id }))
        })
        .catch(() => setRestoring(false))
    },
    [services, navigate],
  )

  const columnHelper = createColumnHelper<UserRow>()
  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: () => <DataTableHeader>Name</DataTableHeader>,
      cell: (info) => <DataTableCell>{info.getValue()}</DataTableCell>,
    }),
    columnHelper.accessor('email', {
      id: 'email',
      header: () => <DataTableHeader>Email</DataTableHeader>,
      cell: (info) => <DataTableCell>{info.getValue()}</DataTableCell>,
    }),
    columnHelper.accessor('role', {
      id: 'role',
      header: () => <DataTableHeader>Role</DataTableHeader>,
      cell: (info) => <DataTableCell>{info.getValue()}</DataTableCell>,
    }),
    columnHelper.display({
      id: 'actions',
      cell: useCallback(
        (cell) => (
          <Actions
            actions={
              !archive
                ? [
                    {
                      title: 'Edit',
                      icon: MdEdit,
                      route: reverse(routes.users.detail, {
                        userId: cell.row.original.id,
                      }),
                    },
                  ]
                : [
                    {
                      title: 'Restore',
                      icon: MdUnarchive,
                      onClick: () => handleRestore(cell.row.original.id),
                    },
                  ]
            }
          />
        ),
        [archive, handleRestore],
      ),
    }),
  ]

  return (
    <DataTable
      meta={data?.meta}
      rows={rows}
      columns={columns as any}
      loading={loading || restoring}
      actions={
        <TableButtons
          buttons={
            !archive
              ? [
                  {
                    title: 'Add New User',
                    icon: MdAdd,
                    route: routes.users.add,
                  },
                ]
              : []
          }
        />
      }
    />
  )
}

export default UsersTable
