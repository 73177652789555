import React, { useCallback, useContext } from 'react'
import { useToast } from '@chakra-ui/react'
import { UseFormSetValue } from 'react-hook-form'
import {
  AvailableServices,
  ServiceContext,
} from '../../../../components/Services'
import FormCard from '../../molecules/shared/FormCard'
import { useUser } from '../../../../hooks'
import GridForm from '../forms/GridForm'
import { userDetails, UserDetailsFields } from '../../../../forms'

const UserDetails: React.FunctionComponent = () => {
  const services: AvailableServices = useContext(ServiceContext)
  const toast = useToast()
  const { user, onUpdate } = useUser()

  const initialise = useCallback(
    (setValue: UseFormSetValue<UserDetailsFields>) => {
      setValue('firstName', user.first_name)
      setValue('lastName', user.last_name)
      setValue('email', user.email)
    },
    [user],
  )

  const renderFields = useCallback(
    (disabled: boolean, readOnly: boolean) => (
      <GridForm
        config={userDetails}
        isDisabled={disabled}
        isReadOnly={readOnly}
      />
    ),
    [],
  )

  const onSubmit = useCallback(
    (
      input: UserDetailsFields,
      setError: (error: string) => void,
      onLoading: (loading: boolean) => void,
      onSaved: () => void,
    ) => {
      setError('')
      onLoading(true)
      services.user
        .update(user.id, {
          first_name: input.firstName,
          last_name: input.lastName,
          email: input.email,
        })
        .then((updated) => {
          onLoading(false)
          onUpdate(updated)
          onSaved()
          toast({
            title: 'User Updated',
            description: `Your changes to ${user.full_name}'s account have been successfully saved`,
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        })
        .catch(() => {
          onLoading(false)
          setError('Sorry, there was a problem saving the details you entered')
        })
    },
    [services, toast, user, onUpdate],
  )

  return (
    <FormCard<UserDetailsFields>
      title="Account Settings"
      subtitle="Here you can change the user's account details"
      initialise={initialise}
      renderFields={renderFields}
      onSubmit={onSubmit}
    />
  )
}

export default UserDetails
