import { useDispatch, useSelector } from 'react-redux'
import {
  getUser,
  getUsersByRole,
  loadUser,
  loadUsersByRole,
  setUser,
} from '../store/users'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import {
  type PaginatedResponse,
  type PaginationMeta,
  Role,
  User,
} from '../types'
import { useParams } from 'react-router-dom'
import { AppDispatch } from '../store'
import { AvailableServices, ServiceContext } from '../components/Services'
import { useQueryParams } from './queryParams'
import { useLoading } from './misc'
import { UsersKey } from '../services/LoadingService'

export type UserTableDataQuery = {
  archive?: boolean
}

export type UserTableData = {
  users: PaginatedResponse<User, PaginationMeta> | null
  loadUsers: (query?: UserTableDataQuery) => void
  loadingUsers: boolean
}

export const useUserTableData = (
  query: UserTableDataQuery = {},
): UserTableData => {
  const services: AvailableServices = useContext(ServiceContext)
  const { params } = useQueryParams()
  const queryRef = useRef<string>('')
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<PaginatedResponse<
    User,
    PaginationMeta
  > | null>(null)

  const loadData = useCallback(
    (query: UserTableDataQuery = {}) => {
      if (params !== null) {
        setLoading(true)
        services.user
          .list({
            ...query,
            ...params,
          })
          .then((response: PaginatedResponse<User, PaginationMeta>) => {
            setLoading(false)
            setData(response)
          })
          .catch(() => {
            setLoading(false)
            setData(null)
          })
      }
    },
    [services, params],
  )

  useEffect(() => {
    const serialised = JSON.stringify({ ...params, ...query })
    if (serialised !== queryRef.current) {
      queryRef.current = serialised
      loadData(query)
    }
  }, [loadData, params, query])

  return {
    users: data,
    loadUsers: loadData,
    loadingUsers: loading,
  }
}

type UserParams = {
  userId?: string
}

export const useUser = () => {
  const { userId } = useParams<UserParams>()
  const { user, loading, exception } = useSelector(getUser)
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    if (userId && userId !== `${user?.id}`) {
      dispatch(loadUser(parseInt(userId)))
    }
  }, [user, userId, dispatch])

  const updateUser = useCallback(
    (user: User | null) => {
      dispatch(setUser(user))
    },
    [dispatch],
  )

  return {
    user,
    loading,
    exception,
    onUpdate: updateUser,
  }
}

export const useUsersByRole = (role: Role) => {
  const roles = useSelector(getUsersByRole)
  const { loading, service } = useLoading(new UsersKey(role))
  const dispatch = useDispatch<AppDispatch>()
  const [users, setUsers] = useState<User[]>([])

  useEffect(() => {
    const index = roles.findIndex(({ role: { slug } }) => slug === role.slug)
    if (index >= 0) {
      setUsers(roles[index].users)
    } else {
      if (!service.isLoading(new UsersKey(role))) {
        dispatch(loadUsersByRole(role))
      }
    }
  }, [role, roles, service, dispatch])

  return { users, loading }
}
