import type {
  AuthToken,
  AuthTokenResponse,
  User,
  UserResponse,
} from '../types'
import { addSeconds } from 'date-fns'

export const mapUser = (data: UserResponse): User => {
  return {
    ...data,
    full_name: `${data.first_name} ${data.last_name}`,
    created_at: new Date(data.created_at),
    updated_at: new Date(data.updated_at),
  }
}

export const mapAuthToken = (data: AuthTokenResponse): AuthToken => {
  return {
    access: data.access_token,
    refresh: data.refresh_token,
    type: data.token_type,
    expires: addSeconds(new Date(), data.expires_in),
  }
}
