import logo from '../../../assets/img/logo-white.svg'

import { Flex, Image, Link, Stack } from '@chakra-ui/react'

import { SidebarContext } from '../../../horizon/contexts/SidebarContext'
import React from 'react'

const Header: React.FunctionComponent<{ sidebarWidth?: any }> = ({
  sidebarWidth,
}) => {
  let mainText = '#fff'
  let navbarBg = 'none'
  let navbarShadow = 'initial'
  let navbarPosition = 'absolute' as 'absolute'

  let brand = (
    <Link
      href="https://www.warranties2000.co.uk"
      display="flex"
      lineHeight="100%"
      fontWeight="bold"
      justifyContent="center"
      alignItems="center"
      color={mainText}
    >
      <Stack
        direction="row"
        spacing="12px"
        alignItems="center"
        justify="center"
      >
        <Image src={logo} w="225px" />
      </Stack>
    </Link>
  )

  return (
    <SidebarContext.Provider value={{ sidebarWidth }}>
      <Flex
        position={navbarPosition}
        top="16px"
        left="50%"
        transform="translate(-50%, 0px)"
        background={navbarBg}
        boxShadow={navbarShadow}
        borderRadius="15px"
        px="16px"
        py="22px"
        mx="auto"
        width="1044px"
        maxW="90%"
        alignItems="center"
        zIndex="3"
      >
        <Flex w="100%" justifyContent="center">
          {brand}
        </Flex>
      </Flex>
    </SidebarContext.Provider>
  )
}

export default Header
