import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import React, { useCallback, useMemo } from 'react'
import { Garage } from '../../../../types'
import { useGarages } from '../../../../hooks'

const GarageMenu: React.FunctionComponent<{
  label: string
  selected: Garage | null
  onSelect: (garage: Garage | null) => void
  disabled?: boolean
}> = ({ label, selected, onSelect, disabled = false }) => {
  const { garages, loading } = useGarages()

  const isDisabled = useMemo(() => disabled || loading, [disabled, loading])

  const selectGarage = useCallback(
    (garage: Garage) => {
      onSelect(selected?.id === garage.id ? null : garage)
    },
    [selected, onSelect],
  )

  return garages?.length > 0 ? (
    <Menu>
      <MenuButton
        as={Button}
        variant="brand"
        rightIcon={<ChevronDownIcon />}
        disabled={isDisabled}
      >
        {label}
      </MenuButton>
      <MenuList>
        {garages.map((garage, index) => (
          <MenuItem
            key={`garage-${index}`}
            onClick={() => selectGarage(garage)}
          >
            {garage.id === selected?.id ? (
              <strong>{garage.name}</strong>
            ) : (
              garage.name
            )}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  ) : null
}

export default GarageMenu
