import { useCallback, useEffect, useState } from 'react'
import { useQueryParams } from './queryParams'

export const useTabs = (
  tabs: string[],
): {
  tab: number
  selectTab: (tab: number) => void
} => {
  const {
    params: { tab },
    updateParams,
  } = useQueryParams()

  const [index, setIndex] = useState<number | undefined>(undefined)

  useEffect(() => {
    const index = tabs.indexOf(tab)
    setIndex(index >= 0 ? index : 0)
  }, [tabs, tab])

  const selectTab = useCallback(
    (tab: number) => {
      const selected = tabs[tab]
      updateParams({ tab: tab === 0 || !selected ? undefined : tabs[tab] })
    },
    [tabs, updateParams],
  )

  return { tab: index, selectTab }
}
