import React, { useMemo } from 'react'
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs as ChakraTabs,
} from '@chakra-ui/react'
import { useTabs } from '../../../../hooks'

const Tabs: React.FunctionComponent<{
  tabs: Array<{
    title: string
    slug: string
    onRender: () => React.ReactElement
  }>
}> = ({ tabs }) => {
  const slugs = useMemo(() => tabs.map(({ slug }) => slug), [tabs])
  const { tab, selectTab } = useTabs(slugs)

  return tab !== undefined ? (
    <ChakraTabs
      isLazy={true}
      variant="soft-rounded"
      colorScheme="brandTabs"
      index={tab}
      onChange={selectTab}
    >
      <TabList>
        {tabs.map(({ title }, index) => (
          <Tab key={index}>{title}</Tab>
        ))}
      </TabList>
      <TabPanels>
        {tabs.map(({ onRender }, index) => (
          <TabPanel key={index} paddingX="0">
            {onRender()}
          </TabPanel>
        ))}
      </TabPanels>
    </ChakraTabs>
  ) : null
}

export default Tabs
