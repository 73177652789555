import * as React from 'react'
import AdminTemplate from '../../templates/AdminTemplate'
import routes from '../../../../routes'
import { Box, Grid, GridItem, Text, useColorModeValue } from '@chakra-ui/react'
import DetailHeader from '../../molecules/shared/DetailHeader'
import WarrantiesTable from '../../organisms/warranties/WarrantiesTable'
import Card from '../../../../horizon/components/card/Card'
import GarageDetails from '../../organisms/garages/GarageDetails'
import { useGarage, useWarrantyTableData } from '../../../../hooks'

const GaragePage: React.FunctionComponent = () => {
  const { garage } = useGarage()
  const { warranties, loadingWarranties } = useWarrantyTableData({ garage })

  const textPrimary = useColorModeValue('secondaryGray.900', 'white')
  return (
    <AdminTemplate
      breadcrumbs={[
        { label: 'Warranties 2000', link: routes.dashboard },
        { label: 'Garages', link: routes.garages.index },
        ...(garage ? [{ label: garage.name }] : []),
      ]}
      title={garage ? garage.name : 'Loading...'}
    >
      {garage ? (
        <Box>
          <Grid marginBottom="20px" templateColumns="repeat(3, 1fr)" gap="20px">
            <GridItem colSpan={2}>
              <DetailHeader
                title={garage.name}
                createdAt={garage.created_at}
                updatedAt={garage.updated_at}
              />
            </GridItem>
            <GridItem />
            <GridItem>
              <GarageDetails />
            </GridItem>
            <GridItem colSpan={3}>
              <Card
                padding="1rem"
                display="flex"
                flexDirection="column"
                gap="1rem"
              >
                <Text
                  fontSize="xl"
                  color={textPrimary}
                  fontWeight="bold"
                  paddingX="0.5rem"
                >
                  Warranties
                </Text>
                <WarrantiesTable
                  data={warranties}
                  loading={loadingWarranties}
                />
              </Card>
            </GridItem>
          </Grid>
        </Box>
      ) : null}
    </AdminTemplate>
  )
}

export default GaragePage
