import { useDispatch, useSelector } from 'react-redux'
import {
  getGarage,
  getGarages,
  loadGarage,
  loadGarages,
  setGarage,
} from '../store/garages'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Garage, type PaginatedResponse, type PaginationMeta } from '../types'
import { useParams } from 'react-router-dom'
import { AppDispatch } from '../store'
import { AvailableServices, ServiceContext } from '../components/Services'
import { useQueryParams } from './queryParams'
import { useLoading } from './misc'
import { GaragesKey } from '../services/LoadingService'

export type GarageTableDataQuery = {}

export type GarageTableData = {
  garages: PaginatedResponse<Garage, PaginationMeta> | null
  loadGarages: (query?: GarageTableDataQuery) => void
  loadingGarages: boolean
}

export const useGarageTableData = (
  query: GarageTableDataQuery = {},
): GarageTableData => {
  const services: AvailableServices = useContext(ServiceContext)
  const { params } = useQueryParams()
  const queryRef = useRef<string>('')
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<PaginatedResponse<
    Garage,
    PaginationMeta
  > | null>(null)

  const loadData = useCallback(
    (_: GarageTableDataQuery = {}) => {
      if (params !== null) {
        setLoading(true)
        services.garage
          .list({
            ...params,
          })
          .then((response: PaginatedResponse<Garage, PaginationMeta>) => {
            setLoading(false)
            setData(response)
          })
          .catch(() => {
            setLoading(false)
            setData(null)
          })
      }
    },
    [services, params],
  )

  useEffect(() => {
    const serialised = JSON.stringify({ ...params, ...query })
    if (serialised !== queryRef.current) {
      queryRef.current = serialised
      loadData(query)
    }
  }, [loadData, params, query])

  return {
    garages: data,
    loadGarages: loadData,
    loadingGarages: loading,
  }
}

type GarageParams = {
  garageId?: string
}

export const useGarage = () => {
  const { garageId } = useParams<GarageParams>()
  const { garage, loading, exception } = useSelector(getGarage)
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    if (garageId && garageId !== `${garage?.id}`) {
      dispatch(loadGarage(parseInt(garageId)))
    }
  }, [garage, garageId, dispatch])

  const updateGarage = useCallback(
    (garage: Garage | null) => {
      dispatch(setGarage(garage))
    },
    [dispatch],
  )

  return {
    garage,
    loading,
    exception,
    onUpdate: updateGarage,
  }
}

export const useGarages = () => {
  const dispatch = useDispatch<AppDispatch>()
  const garages = useSelector(getGarages)
  const { loading, service } = useLoading(new GaragesKey())

  useEffect(() => {
    if (!garages?.length) {
      if (!service.isLoading(new GaragesKey())) {
        dispatch(loadGarages())
      }
    }
  }, [dispatch, garages, service])

  return { garages, loading }
}
