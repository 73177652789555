import * as React from 'react'
import AdminTemplate from '../../templates/AdminTemplate'
import routes from '../../../../routes'
import { Box, Grid, GridItem } from '@chakra-ui/react'
import DetailHeader from '../../molecules/shared/DetailHeader'
import WarrantyCustomer from '../../organisms/warranties/WarrantyCustomer'
import WarrantyAddress from '../../organisms/warranties/WarrantyAddress'
import WarrantyVehicle from '../../organisms/warranties/WarrantyVehicle'
import WarrantyAgent from '../../organisms/warranties/WarrantyAgent'
import { useWarranty } from '../../../../hooks'
import WarrantyGarage from '../../organisms/warranties/WarrantyGarage'
import WarrantyInfo from '../../organisms/warranties/WarrantyInfo'
import WarrantyStatus from '../../organisms/warranties/WarrantyStatus'

const WarrantyPage: React.FunctionComponent = () => {
  const { warranty } = useWarranty()

  return (
    <AdminTemplate
      breadcrumbs={[
        { label: 'Warranties 2000', link: routes.dashboard },
        { label: 'Warranties', link: routes.warranties.index },
        ...(warranty ? [{ label: warranty.policy_number }] : []),
      ]}
      title={warranty ? warranty.policy_number : 'Loading...'}
    >
      {warranty ? (
        <Box>
          <Grid marginBottom="20px" templateColumns="repeat(6, 1fr)" gap="20px">
            <GridItem colSpan={4}>
              <DetailHeader
                title={`Policy: ${warranty.policy_number}`}
                subtitle={warranty.full_name}
              />
            </GridItem>
            <GridItem colSpan={2} />
            <GridItem colSpan={2}>
              <WarrantyAgent />
            </GridItem>
            <GridItem colSpan={2}>
              <WarrantyGarage />
            </GridItem>
            <GridItem colSpan={2}>
              <WarrantyStatus />
            </GridItem>
            <GridItem colSpan={3} display="flex" flexDirection="column">
              <WarrantyCustomer />
            </GridItem>
            <GridItem colSpan={3} display="flex" flexDirection="column">
              <WarrantyInfo />
            </GridItem>
            <GridItem colSpan={3} display="flex" flexDirection="column">
              <WarrantyAddress />
            </GridItem>
            <GridItem colSpan={3} display="flex" flexDirection="column">
              <WarrantyVehicle />
            </GridItem>
          </Grid>
        </Box>
      ) : null}
    </AdminTemplate>
  )
}

export default WarrantyPage
