import React from 'react'
import {
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react'
import { MdMoreVert } from 'react-icons/md'
import { NavLink } from 'react-router-dom'
import { IconType } from 'react-icons'

export type Action = {
  title: string
  icon: IconType
  route?: string
  onClick?: () => void
}

const Actions: React.FunctionComponent<{ actions: Action[] }> = ({
  actions,
}) =>
  actions.length > 0 ? (
    <Menu>
      <MenuButton
        as={IconButton}
        icon={<Icon color="white" as={MdMoreVert} w="20px" h="20px" />}
        variant="brand"
      />
      <MenuList>
        {actions.map(({ title, icon, onClick, route }, index) => (
          <MenuItem
            key={index}
            as={route ? NavLink : 'button'}
            {...(route ? { to: route } : {})}
            {...(onClick ? { onClick } : {})}
            gap="0.5rem"
          >
            <Icon color="secondaryGray.500" as={icon} w="20px" h="20px" />
            {title}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  ) : null

export default Actions
