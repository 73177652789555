import { configureStore } from '@reduxjs/toolkit'

import auth from './auth'
import garages from './garages'
import queryParams from './queryParams'
import products from './products'
import users from './users'
import warranties from './warranties'

export const store = configureStore({
  reducer: {
    auth,
    garages,
    queryParams,
    products,
    users,
    warranties,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
