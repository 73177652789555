import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { AuthState, User } from '../types'
import { RootState } from './index'

const slice = createSlice({
  initialState: {
    user: null,
    loaded: false,
  } as AuthState,
  name: 'auth',
  reducers: {
    setUser: (state, { payload }: PayloadAction<User | null>) => {
      state.user = payload
      state.loaded = true
    },
  },
})

export const { setUser } = slice.actions

export default slice.reducer

export const getUser = (state: RootState): User | null => state.auth.user

export const getAuthLoaded = (state: RootState): boolean => state.auth.loaded
