import * as React from 'react'
import AdminTemplate from '../../templates/AdminTemplate'
import routes from '../../../../routes'
import { Box, Flex, SimpleGrid } from '@chakra-ui/react'
import UserHeader from '../../organisms/users/UserHeader'
import UserDetails from '../../organisms/users/UserDetails'
import UserPassword from '../../organisms/users/UserPassword'
import UserRole from '../../organisms/users/UserRole'
import { useUser } from '../../../../hooks'

const UserPage: React.FunctionComponent = () => {
  const { user } = useUser()

  return (
    <AdminTemplate
      breadcrumbs={[
        { label: 'Warranties 2000', link: routes.dashboard },
        { label: 'Administration' },
        { label: 'Users', link: routes.users.index },
        ...(user ? [{ label: user.full_name }] : []),
      ]}
      title={user ? user.full_name : 'Loading...'}
    >
      {user ? (
        <Box>
          <SimpleGrid
            marginBottom="20px"
            columns={{ sm: 1, lg: 2 }}
            spacing={{ base: '20px', xl: '20px' }}
          >
            <Flex direction="column" gap="20px">
              <UserHeader />
              <UserDetails />
            </Flex>
            <Flex direction="column" gap="20px" flex={0}>
              <UserRole />
              <UserPassword />
            </Flex>
          </SimpleGrid>
        </Box>
      ) : null}
    </AdminTemplate>
  )
}

export default UserPage
