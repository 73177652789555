import React, { useCallback, useEffect } from 'react'
import { Button, HStack, Text } from '@chakra-ui/react'
import Card from '../../../../horizon/components/card/Card'
import {
  FileUpload,
  FileUploadDropzone,
  FileUploadTrigger,
  useFileUpload,
} from '@saas-ui/file-upload'

const UploadCard: React.FunctionComponent<{
  prompt?: string
  accept?: string
  maxFiles?: number
  onSelect: (files: File[]) => void
  loading?: boolean
  disabled?: boolean
  footer?: React.ReactElement
}> = ({
  prompt = 'Drop your image here',
  accept = 'image/*',
  maxFiles = 1,
  onSelect,
  loading = false,
  disabled = false,
  footer,
}) => {
  const { acceptedFiles } = useFileUpload({})

  useEffect(() => {
    onSelect(acceptedFiles)
  }, [onSelect, acceptedFiles])

  return (
    <Card display="flex" flexDirection="column">
      <FileUpload
        opacity={loading || disabled ? 0.5 : 1}
        pointerEvents={loading || disabled ? 'none' : 'auto'}
        transition="opacity 0.25s ease-in-out"
        maxFileSize={1024 * 1024}
        maxFiles={maxFiles}
        onFileChange={useCallback(
          ({ acceptedFiles }) => {
            onSelect(acceptedFiles)
          },
          [onSelect],
        )}
        accept={accept}
      >
        {({ acceptedFiles, deleteFile }) => (
          <FileUploadDropzone>
            <Text fontSize="sm">{prompt}</Text>
            {!acceptedFiles?.length ? (
              <FileUploadTrigger as={Button}>Select</FileUploadTrigger>
            ) : (
              <HStack>
                <Text fontSize="sm">{acceptedFiles[0].name}</Text>
                <Button
                  onClick={(e) => {
                    e.stopPropagation()
                    deleteFile(acceptedFiles[0])
                  }}
                >
                  Clear
                </Button>
              </HStack>
            )}
          </FileUploadDropzone>
        )}
      </FileUpload>
      {footer}
    </Card>
  )
}

export default UploadCard
