import qs from 'qs'
import { API } from './API'
import type {
  APIException,
  PaginatedResponse,
  PaginationMeta,
  PaginationMetaResponse,
  QueryParams,
  SingularResponse,
  User,
  UserChangePassword,
  UserResponse,
  UserUpdate,
} from '../types'
import { mapPaginatedResponse, mapTableQuery, mapUser } from '../mappers'
import { setUser } from '../store/auth'
import { resetRoles } from '../store/users'

export class UserService {
  public async list(
    params: QueryParams,
  ): Promise<PaginatedResponse<User, PaginationMeta>> {
    return (await API.getConnection())
      .get('users', {
        params: mapTableQuery(params),
        paramsSerializer: (params: any) => qs.stringify(params),
      })
      .then(
        (response: {
          data: PaginatedResponse<UserResponse, PaginationMetaResponse>
        }) => mapPaginatedResponse<UserResponse, User>(response.data, mapUser),
      )
      .catch((error: APIException | null) => API.handleError(error))
  }

  public async single(id: number, archive = false): Promise<User> {
    return (await API.getConnection())
      .get(`users/${id}`, {
        params: archive ? { filter: { archive: 'only' } } : {},
        paramsSerializer: (params: any) => qs.stringify(params),
      })
      .then((response: SingularResponse<UserResponse>) =>
        mapUser(response.data.data),
      )
      .catch((error: APIException | null) => API.handleError(error))
  }

  public async create(data: UserUpdate): Promise<User> {
    return (await API.getConnection())
      .post('users', data)
      .then((response: SingularResponse<UserResponse>) => {
        API.getStore().dispatch(resetRoles())
        return mapUser(response.data.data)
      })
      .catch((error: APIException | null) => API.handleError(error))
  }

  public async update(id: number, data: UserUpdate): Promise<User> {
    return (await API.getConnection())
      .put(`users/${id}`, data)
      .then((response: SingularResponse<UserResponse>) => {
        API.getStore().dispatch(resetRoles())
        const user = mapUser(response.data.data)
        if (user.id === API.getStore().getState()['auth'].user.id) {
          API.getStore().dispatch(setUser(user))
        }
        return user
      })
      .catch((error: APIException | null) => API.handleError(error))
  }

  public async changePassword(data: UserChangePassword): Promise<User> {
    const { id, ...params } = data
    return (await API.getConnection())
      .put(`users/${id}`, params)
      .then((response: SingularResponse<UserResponse>) =>
        mapUser(response.data.data),
      )
      .catch((error: APIException | null) => API.handleError(error))
  }

  public async delete(id: number): Promise<void> {
    return (await API.getConnection())
      .delete(`users/${id}`)
      .then(() => {
        API.getStore().dispatch(resetRoles())
      })
      .catch((error: APIException | null) => API.handleError(error))
  }

  public async restore(id: number): Promise<User> {
    return (await API.getConnection())
      .post(`users/${id}/restore`)
      .then((response: SingularResponse<UserResponse>) => {
        API.getStore().dispatch(resetRoles())
        return mapUser(response.data.data)
      })
      .catch((error: APIException | null) => API.handleError(error))
  }
}
