import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { QueryParamsState, QueryParams } from '../types'
import { RootState } from './index'

const slice = createSlice({
  initialState: {
    params: {},
  } as QueryParamsState,
  name: 'queryParams',
  reducers: {
    updateQueryParams: (state, { payload }: PayloadAction<QueryParams>) => {
      state.params = { ...state.params, ...payload }
    },
  },
})

export const { updateQueryParams } = slice.actions

export default slice.reducer

export const getQueryParams = (state: RootState): QueryParams =>
  state.queryParams.params
