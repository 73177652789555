import * as React from 'react'
import AdminTemplate from '../../templates/AdminTemplate'

const Dashboard: React.FunctionComponent = () => {
  return (
    <AdminTemplate
      breadcrumbs={[{ label: 'Warranties 2000' }]}
      title="Dashboard"
    />
  )
}

export default Dashboard
