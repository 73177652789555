import * as React from 'react'
import { useCallback } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import routes from '../../routes'
import Dashboard from './pages/dashboard/Dashboard'
import WarrantiesPage from './pages/warranties/WarrantiesPage'
import UsersPage from './pages/users/UsersPage'
import UsersArchivePage from './pages/archive/UsersPage'
import ProfilePage from './pages/profile/ProfilePage'
import UserPage from './pages/users/UserPage'
import { MANAGEMENT, userCanAccess } from '../../utils'
import { useSelector } from 'react-redux'
import { getUser } from '../../store/auth'
import { Role } from '../../types'
import WarrantyPage from './pages/warranties/WarrantyPage'
import GaragesPage from './pages/garages/GaragesPage'
import GaragePage from './pages/garages/GaragePage'
import WarrantyImportPage from './pages/warranties/WarrantyImportPage'
import WarrantiesAssignPage from './pages/warranties/WarrantiesAssignPage'
import ProductsPage from './pages/products/ProductsPage'
import ProductPage from './pages/products/ProductPage'
import NewUserPage from './pages/users/NewUserPage'
import NewGaragePage from './pages/garages/NewGaragePage'
import NewProductPage from './pages/products/NewProductPage'

const Admin: React.FunctionComponent = (props) => {
  const user = useSelector(getUser)

  const toggleRoute = useCallback(
    (roles: Role[], route: React.ReactElement) => {
      if (userCanAccess(user, roles)) {
        return route
      } else {
        return <Navigate to={`${routes.dashboard}`} replace />
      }
    },
    [user],
  )

  return (
    <Routes>
      <Route path={routes.dashboard} element={<Dashboard {...props} />} />

      <Route
        path={routes.warranties.index}
        element={<WarrantiesPage {...props} />}
      />
      <Route
        path={routes.warranties.import}
        element={toggleRoute(MANAGEMENT, <WarrantyImportPage {...props} />)}
      />
      <Route
        path={routes.warranties.assign}
        element={toggleRoute(MANAGEMENT, <WarrantiesAssignPage {...props} />)}
      />
      <Route
        path={routes.warranties.detail}
        element={<WarrantyPage {...props} />}
      />

      <Route
        path={routes.garages.index}
        element={toggleRoute(MANAGEMENT, <GaragesPage {...props} />)}
      />
      <Route
        path={routes.garages.add}
        element={toggleRoute(MANAGEMENT, <NewGaragePage {...props} />)}
      />
      <Route
        path={routes.garages.detail}
        element={toggleRoute(MANAGEMENT, <GaragePage {...props} />)}
      />

      <Route
        path={routes.products.index}
        element={toggleRoute(MANAGEMENT, <ProductsPage {...props} />)}
      />
      <Route
        path={routes.products.add}
        element={toggleRoute(MANAGEMENT, <NewProductPage {...props} />)}
      />
      <Route
        path={routes.products.detail}
        element={toggleRoute(MANAGEMENT, <ProductPage {...props} />)}
      />

      <Route
        path={routes.users.index}
        element={toggleRoute(MANAGEMENT, <UsersPage {...props} />)}
      />
      <Route
        path={routes.users.add}
        element={toggleRoute(MANAGEMENT, <NewUserPage {...props} />)}
      />
      <Route
        path={routes.users.detail}
        element={toggleRoute(MANAGEMENT, <UserPage {...props} />)}
      />

      <Route
        path={routes.archive.users}
        element={toggleRoute(MANAGEMENT, <UsersArchivePage {...props} />)}
      />

      <Route path={routes.profile} element={<ProfilePage {...props} />} />
    </Routes>
  )
}

export default Admin
