import * as React from 'react'
import { useCallback, useMemo, useState } from 'react'

import { createColumnHelper } from '@tanstack/react-table'
import DataTableHeader from '../../molecules/shared/DataTableHeader'
import DataTableCell from '../../molecules/shared/DataTableCell'
import DataTable from '../../molecules/shared/DataTable'
import { Product, ProductTariff } from '../../../../types'
import { formatCurrency, formatMonths } from '../../../../utils'
import Actions from '../../molecules/shared/Actions'
import { MdAdd, MdEdit } from 'react-icons/md'
import TariffModal from './TariffModal'
import TableButtons from '../../molecules/shared/TableButtons'

type TariffRow = {
  record: ProductTariff
  duration: string
  claimLimit: string
  grossPrice: string
  vat: string
  netPrice: string
  adminFee: string
  premium: string
  plan: string
  initial: string
}

const TariffsTable: React.FunctionComponent<{
  product: Product
  loading: boolean
}> = ({ product, loading }) => {
  const [selectedTariff, setSelectedTariff] = useState<
    ProductTariff | undefined
  >(undefined)
  const [showForm, setShowForm] = useState(false)

  const rows = useMemo<TariffRow[]>(
    () =>
      (product.tariffs ?? []).map((tariff: ProductTariff) => ({
        record: tariff,
        duration: formatMonths(tariff.duration),
        claimLimit: tariff.claim_limit
          ? formatCurrency(tariff.claim_limit)
          : 'N/A',
        grossPrice: formatCurrency(tariff.gross_price),
        vat: formatCurrency(tariff.vat),
        netPrice: formatCurrency(tariff.net_price),
        adminFee: formatCurrency(tariff.admin_fee),
        premium: formatCurrency(tariff.premium),
        plan: tariff.plan.name,
        initial: formatCurrency(tariff.initial),
      })),
    [product],
  )

  const columnHelper = createColumnHelper<TariffRow>()
  const columns = [
    columnHelper.accessor('duration', {
      id: 'duration',
      header: () => <DataTableHeader>Duration</DataTableHeader>,
      cell: (info) => <DataTableCell>{info.getValue()}</DataTableCell>,
    }),
    columnHelper.accessor('claimLimit', {
      id: 'claimLimit',
      header: () => <DataTableHeader>Claim Limit</DataTableHeader>,
      cell: (info) => <DataTableCell>{info.getValue()}</DataTableCell>,
    }),
    columnHelper.accessor('grossPrice', {
      id: 'grossPrice',
      header: () => <DataTableHeader>Gross Price</DataTableHeader>,
      cell: (info) => <DataTableCell>{info.getValue()}</DataTableCell>,
    }),
    columnHelper.accessor('vat', {
      id: 'vat',
      header: () => <DataTableHeader>VAT</DataTableHeader>,
      cell: (info) => <DataTableCell>{info.getValue()}</DataTableCell>,
    }),
    columnHelper.accessor('netPrice', {
      id: 'netPrice',
      header: () => <DataTableHeader>Net Price</DataTableHeader>,
      cell: (info) => <DataTableCell>{info.getValue()}</DataTableCell>,
    }),
    columnHelper.accessor('adminFee', {
      id: 'adminFee',
      header: () => <DataTableHeader>Admin Fee</DataTableHeader>,
      cell: (info) => <DataTableCell>{info.getValue()}</DataTableCell>,
    }),
    columnHelper.accessor('premium', {
      id: 'premium',
      header: () => <DataTableHeader>Premium</DataTableHeader>,
      cell: (info) => <DataTableCell>{info.getValue()}</DataTableCell>,
    }),
    columnHelper.accessor('plan', {
      id: 'plan',
      header: () => <DataTableHeader>Plan</DataTableHeader>,
      cell: (info) => <DataTableCell>{info.getValue()}</DataTableCell>,
    }),
    columnHelper.accessor('initial', {
      id: 'initial',
      header: () => <DataTableHeader>Deposit</DataTableHeader>,
      cell: (info) => <DataTableCell>{info.getValue()}</DataTableCell>,
    }),
    columnHelper.display({
      id: 'actions',
      cell: useCallback(
        (cell) => (
          <Actions
            actions={[
              {
                title: 'Edit',
                icon: MdEdit,
                onClick: () => {
                  setSelectedTariff(cell.row.original.record)
                  setShowForm(true)
                },
              },
            ]}
          />
        ),
        [],
      ),
    }),
  ]

  return (
    <>
      <DataTable
        meta={null}
        rows={rows}
        columns={columns as any}
        loading={loading}
        canSearch={false}
        actions={
          <TableButtons
            buttons={[
              {
                title: 'Add New Tariff',
                icon: MdAdd,
                onClick: () => {
                  setSelectedTariff(undefined)
                  setShowForm(true)
                },
              },
            ]}
          />
        }
      />
      <TariffModal
        tariff={selectedTariff}
        open={showForm}
        onClose={() => setShowForm(false)}
      />
    </>
  )
}

export default TariffsTable
