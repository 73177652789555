import Card from '../../../../horizon/components/card/Card'
import { WarrantyImportField, WarrantyImportHeader } from '../../../../types'
import {
  FormControl,
  FormLabel,
  Select,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useRef } from 'react'
import { WARRANTY_IMPORT_FIELDS } from '../../../../utils'

const WarrantyImportHeaderCard: React.FunctionComponent<{
  header: WarrantyImportHeader
  disabled?: boolean
  onUpdate: (
    header: WarrantyImportHeader,
    field: WarrantyImportField | undefined,
  ) => void
}> = ({ header, disabled = false, onUpdate }) => {
  const selectRef = useRef<HTMLSelectElement>(null)

  useEffect(() => {
    if (selectRef.current) {
      selectRef.current.value = header.field?.name ?? '--- Ignore ---'
    }
  }, [header])

  const handleChange = useCallback(
    (e) => {
      onUpdate(
        header,
        WARRANTY_IMPORT_FIELDS.find(({ name }) => name === e.target.value),
      )
    },
    [header, onUpdate],
  )

  const textPrimary = useColorModeValue('secondaryGray.900', 'white')
  return (
    <Card display="flex" flexDirection="column" gap="1rem">
      <Text fontSize="large" color={textPrimary} fontWeight="bold">
        {header.title}
      </Text>
      <FormControl isDisabled={disabled}>
        <FormLabel display="none">{header.title}</FormLabel>
        <Select
          fontSize="sm"
          fontWeight="500"
          size="lg"
          color={textPrimary}
          ref={selectRef}
          value={header.field?.name ?? '--- Ignore ---'}
          onChange={handleChange}
        >
          <option>--- Ignore ---</option>
          {WARRANTY_IMPORT_FIELDS.map((field, index) => (
            <option key={index} value={field.name}>
              {field.title}
            </option>
          ))}
        </Select>
      </FormControl>
    </Card>
  )
}

export default WarrantyImportHeaderCard
