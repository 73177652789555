import { Role, User } from '../types'

export const DEVELOPER: Role = { name: 'Developer', slug: 'developer' }
export const ADMINISTRATOR: Role = {
  name: 'Administrator',
  slug: 'administrator',
}
export const TELESALES: Role = { name: 'Telesales', slug: 'telesales' }

export const MANAGEMENT = [DEVELOPER, ADMINISTRATOR]

export const ROLES: Role[] = [DEVELOPER, ADMINISTRATOR, TELESALES]

export const userCanAccess = (user: User, roles: Role[]): boolean => {
  return roles.map(({ slug }) => slug).includes(user.role.slug)
}
