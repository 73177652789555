import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { APIException, Garage, GaragesState, GarageState } from '../types'
import { ServiceProvider } from '../services/ServiceProvider'
import { RootState } from './index'
import { GaragesKey } from '../services/LoadingService'
import { API } from '../services/API'

export const loadGarage = createAsyncThunk(
  'garages/loadGarage',
  async (
    id: number,
  ): Promise<{ garage?: Garage; exception?: APIException | null }> =>
    ServiceProvider.getServices()
      .garage.single(id)
      .then((garage) => ({
        garage,
      }))
      .catch((exception: APIException | null) => ({
        exception,
      })),
)

export const loadGarages = createAsyncThunk(
  'users/loadGarages',
  async (): Promise<{ garages: Garage[] }> =>
    new Promise(async (resolve) => {
      let garages: Garage[] = []
      if (API.hasToken()) {
        garages = await ServiceProvider.getServices()
          .garage.list({ page: 1, length: 1000 })
          .then((response) => response.data)
          .catch(() => [])
      }
      resolve({ garages })
    }),
)

const slice = createSlice({
  initialState: {
    garage: {
      garage: null,
      loading: false,
      exception: null,
    },
    garages: [],
  } as GaragesState,
  name: 'garages',
  reducers: {
    setGarage: (state, { payload }: PayloadAction<Garage | null>) => {
      state.garage.garage = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadGarage.pending, (state) => {
      state.garage.garage = null
      state.garage.loading = true
      state.garage.exception = null
    })
    builder.addCase(loadGarage.rejected, (state) => {
      state.garage.loading = false
    })
    builder.addCase(
      loadGarage.fulfilled,
      (state, { payload: { garage, exception } }) => {
        state.garage.loading = false
        state.garage.garage = garage ?? null
        state.garage.exception = exception ?? null
      },
    )
    builder.addCase(loadGarages.pending, () => {
      ServiceProvider.getServices().loading.toggle(new GaragesKey(), true)
    })
    builder.addCase(loadGarages.rejected, () => {
      ServiceProvider.getServices().loading.toggle(new GaragesKey(), false)
    })
    builder.addCase(
      loadGarages.fulfilled,
      (state, { payload: { garages } }) => {
        state.garages = garages
        ServiceProvider.getServices().loading.toggle(new GaragesKey(), false)
      },
    )
  },
})

export const { setGarage } = slice.actions

export default slice.reducer

export const getGarage = (state: RootState): GarageState => state.garages.garage

export const getGarages = (state: RootState): Array<Garage> | null =>
  state.garages.garages
