import React from 'react'
import { ProductTariff } from '../../../../types'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import TariffDetails from './TariffDetails'

const TariffModal: React.FunctionComponent<{
  tariff?: ProductTariff
  open: boolean
  onClose: () => void
}> = ({ tariff, open, onClose }) => {
  return (
    <Modal closeOnOverlayClick={false} isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        minWidth="calc(100vh - 4rem)"
        maxWidth="1000px"
        paddingBottom="1rem"
      >
        <ModalHeader>{tariff ? 'Edit Tariff' : 'Add New Tariff'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TariffDetails tariff={tariff} onSaved={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default TariffModal
