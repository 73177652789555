import axios, { AxiosInstance } from 'axios'
import { Store } from 'redux'
import {
  APIException,
  AuthToken,
  AuthTokenResponse,
  AuthTokenSerialised,
  StoreRoot,
} from '../types'
import { mapAuthToken } from '../mappers'
import { isBefore, subMinutes } from 'date-fns'
import { setUser } from '../store/auth'

export class API {
  private static store: Store<StoreRoot>
  private static connection: AxiosInstance
  private static token: AuthToken | null = null

  static initialise(store: Store<StoreRoot>) {
    API.store = store
    const tokenData = localStorage.getItem('auth')
    if (tokenData) {
      const token = JSON.parse(tokenData) as AuthTokenSerialised
      API.setToken(
        {
          ...token,
          expires: new Date(token.expires),
        } as AuthToken,
        false,
      )
    }
  }

  static getStore() {
    return API.store
  }

  static hasToken(): boolean {
    return !!API.token
  }

  static setToken(token: AuthToken | null, store: boolean) {
    if (token) {
      if (API.connection) {
        API.connection.defaults.headers.common['Authorization'] =
          `${token.type} ${token.access}`
      }
    }
    API.token = token
    if (store) {
      if (token) {
        localStorage.setItem(
          'auth',
          JSON.stringify({
            ...token,
            expires: token.expires.toISOString(),
          } as AuthTokenSerialised),
        )
      } else {
        localStorage.removeItem('auth')
        API.store.dispatch(setUser(null))
      }
    }
  }

  static async getConnection(): Promise<AxiosInstance> {
    return new Promise(async (resolve) => {
      if (!API.connection) {
        API.connection = axios.create({
          baseURL: `${process.env.REACT_APP_API_URL}/api/manage/`,
          timeout: 10000,
          responseType: 'json',
        })
        API.connection.interceptors.request.use(
          (config) => {
            return new Promise((resolve) => {
              const authToken = API.token
              const done = (authToken: AuthToken | null) => {
                if (authToken) {
                  config.headers.Authorization = `${authToken.type} ${authToken.access}`
                }
                resolve(config)
              }
              const exclusions = [
                'auth/login',
                'auth/refresh',
                'auth/forgot-password',
                'auth/reset-password',
              ]
              if (exclusions.indexOf(config.url) < 0) {
                if (
                  authToken?.expires &&
                  isBefore(subMinutes(authToken.expires, 2), new Date())
                ) {
                  API.connection
                    .post('auth/refresh', {
                      token: authToken.refresh,
                    })
                    .then((response: { data: AuthTokenResponse }) => {
                      const token = mapAuthToken(response.data)
                      API.setToken(token, true)
                      done(token)
                    })
                    .catch(() => {
                      API.store.dispatch(setUser(null))
                      API.setToken(null, true)
                      done(null)
                    })
                } else {
                  done(authToken)
                }
              } else {
                done(null)
              }
            })
          },
          (error) => Promise.reject(error),
        )
      }
      resolve(API.connection)
    })
  }

  static handleError(error?: APIException | null) {
    if (error && error.response) {
      if (error.response.status === 403) {
        API.store.dispatch(setUser(null))
        localStorage.setItem('token', '')
      }
      return Promise.reject(error.response.data)
    } else {
      return Promise.reject(error)
    }
  }
}
