import { useContext, useEffect, useState } from 'react'
import { AvailableServices, ServiceContext } from '../components/Services'
import { LoadingKey } from '../services/LoadingService'

export const useLoading = (key: LoadingKey) => {
  const services = useContext<AvailableServices>(ServiceContext)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const subscriberID = services.loading.subscribe(key, setLoading)
    return () => services.loading.unsubscribe(subscriberID)
  }, [key, services])

  return { loading, service: services.loading }
}
