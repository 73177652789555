import * as React from 'react'
import { useCallback, useMemo } from 'react'

import { createColumnHelper } from '@tanstack/react-table'
import DataTableHeader from '../../molecules/shared/DataTableHeader'
import DataTableCell from '../../molecules/shared/DataTableCell'
import DataTable from '../../molecules/shared/DataTable'
import type {
  PaginatedResponse,
  PaginationMeta,
  Product,
} from '../../../../types'
import { format } from 'date-fns'
import { DATE_FORMAT } from '../../../../utils'
import Actions from '../../molecules/shared/Actions'
import { MdAdd, MdVisibility } from 'react-icons/md'
import { reverse } from 'named-urls'
import routes from '../../../../routes'
import TableButtons from '../../molecules/shared/TableButtons'

type ProductRow = {
  id: number
  name: string
  dateAdded: string
}

const ProductsTable: React.FunctionComponent<{
  data: PaginatedResponse<Product, PaginationMeta>
  loading: boolean
}> = ({ data, loading }) => {
  const rows = useMemo<ProductRow[]>(
    () =>
      (data?.data ?? []).map((product: Product) => ({
        id: product.id,
        name: product.name,
        dateAdded: format(product.created_at, DATE_FORMAT),
      })),
    [data],
  )

  const columnHelper = createColumnHelper<ProductRow>()
  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: () => <DataTableHeader>Name</DataTableHeader>,
      cell: (info) => <DataTableCell>{info.getValue()}</DataTableCell>,
    }),
    columnHelper.accessor('dateAdded', {
      id: 'renewalDate',
      header: () => <DataTableHeader>Date Added</DataTableHeader>,
      cell: (info) => <DataTableCell>{info.getValue()}</DataTableCell>,
    }),
    columnHelper.display({
      id: 'actions',
      cell: useCallback(
        (cell) => (
          <Actions
            actions={[
              {
                title: 'View',
                icon: MdVisibility,
                route: reverse(routes.products.detail, {
                  productId: cell.row.original.id,
                }),
              },
            ]}
          />
        ),
        [],
      ),
    }),
  ]

  return (
    <DataTable
      meta={data?.meta}
      rows={rows}
      columns={columns as any}
      loading={loading}
      actions={
        <TableButtons
          buttons={[
            {
              title: 'Add New Product',
              icon: MdAdd,
              route: routes.products.add,
            },
          ]}
        />
      }
    />
  )
}

export default ProductsTable
