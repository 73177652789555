import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import Card from '../../../../horizon/components/card/Card'
import { FormProvider, useForm, UseFormSetValue } from 'react-hook-form'

interface FormCardProps<T extends object> {
  title?: string
  subtitle?: string
  initialise?: (setValue: UseFormSetValue<T>) => void
  renderFields: (disabled: boolean, readOnly: boolean) => React.ReactElement
  onSubmit: (
    input: T,
    setError: (error: string) => void,
    onLoading: (loading: boolean) => void,
    onSaved: () => void,
  ) => void
  editLabel?: string
  cancelLabel?: string
  saveLabel?: string
  readOnly?: boolean
  fill?: boolean
}

function FormCard<T extends object>({
  title,
  subtitle,
  initialise,
  renderFields,
  onSubmit,
  editLabel = 'Edit',
  cancelLabel = 'Cancel',
  saveLabel = 'Save Changes',
  readOnly = false,
  fill = true,
}: FormCardProps<T>) {
  const form = useForm<T>()

  useEffect(() => {
    if (initialise) {
      initialise(form.setValue)
    }
  }, [initialise, form.setValue])

  const onClose = useCallback(() => {
    if (initialise) {
      initialise(form.setValue)
    }
    form.clearErrors()
    setEdit(false)
  }, [initialise, form])

  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(false)
  const disabled = useMemo(
    () => !readOnly && (!edit || loading),
    [edit, loading, readOnly],
  )
  const [error, setError] = useState('')

  const handleSubmit = useCallback(
    (input: T) => {
      onSubmit(input, setError, setLoading, () => onClose())
    },
    [onSubmit, onClose],
  )

  const textPrimary = useColorModeValue('secondaryGray.900', 'white')
  const textSecondary = 'secondaryGray.600'
  return (
    <FormProvider {...form}>
      <Card
        as="form"
        onSubmit={form.handleSubmit(handleSubmit)}
        noValidate={true}
        display="flex"
        flex={fill ? 1 : 0}
        flexDirection="column"
      >
        {title || subtitle ? (
          <Flex direction="column" marginBottom="1.5rem">
            {title ? (
              <Text fontSize="xl" color={textPrimary} fontWeight="bold">
                {title}
              </Text>
            ) : null}
            {subtitle ? (
              <Text fontSize="md" color={textSecondary}>
                {subtitle}
              </Text>
            ) : null}
          </Flex>
        ) : null}
        {error ? (
          <Alert status="error">
            <AlertIcon />
            {error}
          </Alert>
        ) : null}
        {renderFields(disabled, readOnly)}
        <Box flex={1} />
        <Flex
          direction="row"
          gap="10px"
          justifyContent="space-between"
          marginTop="2rem"
        >
          {readOnly ? null : edit ? (
            <>
              <Button
                variant="outline"
                onClick={onClose}
                minW="183px"
                fontSize="sm"
                fontWeight="500"
                isDisabled={loading}
              >
                {cancelLabel}
              </Button>
              <Button
                variant="brand"
                type="submit"
                minW="183px"
                fontSize="sm"
                fontWeight="500"
                isDisabled={loading}
              >
                {saveLabel}
              </Button>
            </>
          ) : (
            <Button
              variant="outline"
              onClick={() => setEdit(true)}
              minW="183px"
              fontSize="sm"
              fontWeight="500"
              isDisabled={loading}
            >
              {editLabel}
            </Button>
          )}
        </Flex>
      </Card>
    </FormProvider>
  )
}

export default FormCard
