import React, { PropsWithChildren } from 'react'

import logo from '../../../../assets/img/logo.svg'
import logoWhite from '../../../../assets/img/logo-white.svg'
import {
  Box,
  Flex,
  Image,
  Stack,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'
import {
  renderThumb,
  renderTrack,
  renderView,
} from '../../../../horizon/components/scrollbar/Scrollbar'
import { Scrollbars } from 'react-custom-scrollbars-2'
import Links from './Links'

const Sidebar: React.FunctionComponent<
  PropsWithChildren<{
    routes: RoutesType[]
    hovered: boolean
    setHovered: (toggle: boolean) => void
  }>
> = ({ routes, hovered, setHovered }) => {
  const { colorMode } = useColorMode()
  const variantChange = '0.2s linear'
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
    'unset',
  )
  const sidebarBg = useColorModeValue('white', 'navy.800')
  const sidebarRadius = '30px'
  const sidebarMargins = '0px'
  return (
    <Box
      display={{ sm: 'none', xl: 'block' }}
      position="fixed"
      minH="100%"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Box
        bg={sidebarBg}
        transition={variantChange}
        width="285px"
        height="calc(100vh - 32px)"
        marginLeft={{
          sm: '16px',
        }}
        marginY={{
          sm: '16px',
        }}
        margin={sidebarMargins}
        borderRadius={sidebarRadius}
        minHeight="100%"
        overflowX="hidden"
        boxShadow={shadow}
      >
        <Scrollbars
          autoHide
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={renderView}
        >
          <Flex
            direction="column"
            height="100%"
            paddingTop="1.5rem"
            borderRadius="30px"
            alignItems="center"
            width="240px"
          >
            <Image
              src={colorMode === 'light' ? logo : logoWhite}
              width="200px"
              marginBottom="2rem"
            />
            <Stack
              direction="column"
              marginBottom="auto"
              marginTop="0.5rem"
              width="100%"
            >
              <Box display="flex" flexDirection="column">
                <Links mini={false} hovered={hovered} routes={routes} />
              </Box>
            </Stack>
          </Flex>
        </Scrollbars>
      </Box>
    </Box>
  )
}

export default Sidebar
