import { FormConfig, FormFieldType } from '../types'

export type UserDetailsFields = {
  firstName: string
  lastName: string
  email: string
}

export const userDetails: FormConfig = {
  columns: 2,
  fields: [
    {
      name: 'firstName',
      label: 'First Name',
      requiredError: 'Please enter a first name',
      type: FormFieldType.Text,
    },
    {
      name: 'lastName',
      label: 'Last Name',
      requiredError: 'Please enter a last name',
      type: FormFieldType.Text,
    },
    {
      name: 'email',
      label: 'Email Address',
      requiredError: 'Please enter an email address',
      type: FormFieldType.Email,
    },
  ],
}

export type ChangeUserPasswordFields = {
  password: string
  repeatPassword: string
}

export const changeUserPassword: FormConfig = {
  columns: 2,
  fields: [
    {
      name: 'password',
      label: 'New Password',
      requiredError: 'Please enter a new password',
      type: FormFieldType.Password,
    },
    {
      name: 'repeatPassword',
      label: 'Repeat Password',
      requiredError: 'Please repeat the new password',
      type: FormFieldType.Password,
      validation: (value: string, formValues: ChangeUserPasswordFields) =>
        !value || value === formValues.password
          ? undefined
          : 'The passwords must match',
    },
  ],
}
