import React from 'react'
import {
  Button,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'

const ConfirmationModal: React.FunctionComponent<{
  open: boolean
  title: string
  body?: string
  onConfirm?: () => void
  onDeny?: () => void
  onClose: () => void
  loading?: boolean
}> = ({ open, title, body, onConfirm, onDeny, onClose, loading = false }) => {
  return (
    <Modal closeOnOverlayClick={false} isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          as={Grid}
          templateColumns="1fr 1fr"
          gap="2rem"
          paddingBottom="2rem"
        >
          {body ? (
            <GridItem colSpan={2}>
              <Text>{body}</Text>
            </GridItem>
          ) : null}
          {onConfirm ? (
            <Button colorScheme="green" onClick={onConfirm} isLoading={loading}>
              Yes
            </Button>
          ) : null}
          {onDeny ? (
            <Button colorScheme="red" onClick={onDeny} isLoading={loading}>
              No
            </Button>
          ) : null}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ConfirmationModal
