// Chakra imports
import { Avatar, Flex, Text, useColorModeValue } from '@chakra-ui/react'
import Card from '../../../../horizon/components/card/Card'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useUser } from '../../../../hooks'
import Actions from '../../molecules/shared/Actions'
import { MdArchive } from 'react-icons/md'
import {
  AvailableServices,
  ServiceContext,
} from '../../../../components/Services'
import ConfirmationModal from '../../molecules/shared/ConfirmationModal'
import { useNavigate } from 'react-router-dom'
import routes from '../../../../routes'
import { useSelector } from 'react-redux'
import { getUser } from '../../../../store/auth'

const UserHeader: React.FunctionComponent = () => {
  const services = useContext<AvailableServices>(ServiceContext)
  const navigate = useNavigate()
  const { user } = useUser()
  const currentUser = useSelector(getUser)
  const canArchive = useMemo(
    () => user.id !== currentUser.id,
    [user, currentUser],
  )
  const [showArchive, setShowArchive] = useState(false)
  const [archiving, setArchiving] = useState(false)

  const handleArchive = useCallback(() => {
    setArchiving(true)
    services.user
      .delete(user.id)
      .then(() => {
        setArchiving(false)
        navigate(routes.users.index)
      })
      .catch(() => setArchiving(false))
  }, [services, navigate, user])

  const textColour = useColorModeValue('secondaryGray.900', 'white')
  const avatarColour = useColorModeValue('navy.800', 'white')
  const avatarTextColour = useColorModeValue('white', 'navy.800')
  return (
    <Card alignItems="center">
      <Flex
        width="100%"
        bgGradient="linear(to-b, brand.400, brand.600)"
        minHeight="127px"
        borderRadius="16px"
      ></Flex>
      <Avatar
        marginX="auto"
        name={user.full_name}
        bg={avatarColour}
        textColor={avatarTextColour}
        height="87px"
        width="87px"
        marginTop="-43px"
        marginBottom="15px"
      />
      <Flex alignItems="center" gap="1rem" justifyContent="center">
        <Text fontSize="2xl" textColor={textColour} fontWeight="700">
          {user.full_name}
        </Text>
        <Actions
          actions={[
            {
              title: 'Archive',
              icon: MdArchive,
              onClick: () => setShowArchive(true),
            },
          ]}
        />
      </Flex>
      <ConfirmationModal
        open={showArchive}
        title="Archive this user?"
        body={
          canArchive
            ? `Are you sure you want to archive ${user.full_name}? This will remove will of their access to the system. They can be restored from the archive.`
            : "You can't archive your own user. Doing so would lock you out of the system."
        }
        loading={archiving}
        onConfirm={canArchive ? handleArchive : undefined}
        onDeny={canArchive ? () => setShowArchive(false) : undefined}
        onClose={() => setShowArchive(false)}
      />
    </Card>
  )
}

export default UserHeader
