import { Box, Flex } from '@chakra-ui/react'
import Card from '../../../horizon/components/card/Card'
import React from 'react'
import Header from '../molecules/Header'
import Footer from '../molecules/Footer'

const AccessTemplate: React.FunctionComponent = ({ children }) => {
  return (
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
      mx={{ base: '10px', lg: '0px' }}
      minH="100vh"
    >
      <Box
        position="absolute"
        minH={{ base: '50vh', md: '50vh' }}
        maxH={{ base: '50vh', md: '50vh' }}
        w={{ md: 'calc(100vw)' }}
        maxW={{ md: 'calc(100vw)' }}
        left="0"
        right="0"
        bgRepeat="no-repeat"
        overflow="hidden"
        top="0"
        bgGradient="linear(to-b, purple.900, purple.600)"
        mx={{ md: 'auto' }}
      />
      <Header />
      <Card
        w="100%"
        h="max-content"
        mx="auto"
        maxW="36rem"
        mt={{ base: '140px', md: '14vh' }}
        mb={{ base: '50px', lg: 'auto' }}
        p={{ base: '10px', md: '50px' }}
        pt={{ base: '30px', md: '50px' }}
      >
        {children}
      </Card>
      <Footer />
    </Flex>
  )
}

export default AccessTemplate
