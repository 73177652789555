import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'

interface FormWrapperProps<T extends object> {
  children: (props: {
    as: 'form'
    onSubmit: () => void
    noValidate: true
  }) => React.ReactElement
  onSubmit: (input: T) => void
}

function FormWrapper<T extends object>({
  children,
  onSubmit,
}: FormWrapperProps<T>) {
  const form = useForm<T>()

  return (
    <FormProvider {...form}>
      {children({
        as: 'form',
        onSubmit: form.handleSubmit(onSubmit),
        noValidate: true,
      })}
    </FormProvider>
  )
}

export default FormWrapper
