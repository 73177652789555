import { API } from './API'
import type {
  APIException,
  AuthTokenResponse,
  SingularResponse,
  User,
  UserResponse,
} from '../types'
import { mapAuthToken, mapUser } from '../mappers'

export class AuthService {
  async login(email: string, password: string): Promise<void> {
    return (await API.getConnection())
      .post('auth/login', { email, password })
      .then((response: { data: AuthTokenResponse }) => {
        API.setToken(mapAuthToken(response.data), true)
      })
      .catch((error: APIException | null) => API.handleError(error))
  }

  async me(): Promise<User> {
    if (API.hasToken()) {
      return (await API.getConnection())
        .get('auth/me')
        .then((response: SingularResponse<UserResponse>) =>
          mapUser(response.data.data),
        )
        .catch((error: APIException | null) => API.handleError(error))
    } else {
      return new Promise((_, reject) => reject(null))
    }
  }

  async logout(): Promise<void> {
    return (await API.getConnection())
      .post('auth/logout')
      .then(() => {
        API.setToken(null, true)
      })
      .catch((error: APIException | null) => API.handleError(error))
  }

  async forgotPassword(email: string, resetUrl: string): Promise<void> {
    return (await API.getConnection())
      .post('auth/forgot-password', { email, reset_url: resetUrl })
      .then(() => {})
      .catch((error: APIException | null) => API.handleError(error))
  }

  async resetPassword(
    email: string,
    password: string,
    token: string,
  ): Promise<void> {
    return (await API.getConnection())
      .post('auth/reset-password', { email, password, token })
      .then((response: { data: AuthTokenResponse }) => {
        API.setToken(mapAuthToken(response.data), true)
      })
      .catch((error: APIException | null) => API.handleError(error))
  }
}
