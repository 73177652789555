import * as React from 'react'
import { useCallback, useMemo } from 'react'

import { createColumnHelper, RowSelectionState } from '@tanstack/react-table'
import DataTableHeader from '../../molecules/shared/DataTableHeader'
import DataTableCell from '../../molecules/shared/DataTableCell'
import DataTable from '../../molecules/shared/DataTable'
import type {
  PaginatedResponse,
  PaginationMeta,
  Warranty,
  WarrantyStatus,
} from '../../../../types'
import { format } from 'date-fns'
import { DATE_FORMAT } from '../../../../utils'
import Actions from '../../molecules/shared/Actions'
import { MdVisibility } from 'react-icons/md'
import { reverse } from 'named-urls'
import routes from '../../../../routes'
import { Checkbox, Text } from '@chakra-ui/react'

type WarrantyRow = {
  id: number
  name: string
  policyNumber: string
  renewalDate: string
  status: WarrantyStatus | null
  garage: string
  agent: string
  warranty: Warranty
}

const WarrantiesTable: React.FunctionComponent<{
  data: PaginatedResponse<Warranty, PaginationMeta>
  loading: boolean
  showGarage?: boolean
  actions?: React.ReactElement
  selectedWarranties?: Warranty[]
  onChangeSelection?: (warranties: Warranty[]) => void
}> = ({
  data,
  loading,
  showGarage = false,
  actions,
  selectedWarranties,
  onChangeSelection,
}) => {
  const rows = useMemo<WarrantyRow[]>(
    () =>
      (data?.data ?? []).map((warranty: Warranty) => ({
        id: warranty.id,
        name: warranty.full_name,
        policyNumber: warranty.policy_number,
        renewalDate: format(warranty.expires_on, DATE_FORMAT),
        status: warranty.status,
        garage: warranty.garage?.name ?? '-',
        agent: warranty.user?.full_name ?? '-',
        warranty,
      })),
    [data],
  )

  const selectedRows = useMemo(() => {
    const selected: RowSelectionState = {}
    for (let warranty of selectedWarranties ?? []) {
      selected[warranty.id] = true
    }
    return selected as RowSelectionState
  }, [selectedWarranties])

  const onRowSelectionChange = useCallback(
    (selection: RowSelectionState) => {
      const selectedIDs = Object.keys(selection)
      onChangeSelection(
        data.data.filter(
          (warranty) =>
            selectedIDs.includes(`${warranty.id}`) &&
            selection[`${warranty.id}`],
        ),
      )
    },
    [onChangeSelection, data],
  )

  const columnHelper = createColumnHelper<WarrantyRow>()

  const selectionHeader = useCallback(
    ({ table }) => (
      <Checkbox
        colorScheme="brand"
        id="selectAll"
        isChecked={table.getIsAllRowsSelected()}
        isIndeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    [],
  )

  const selectionCell = useCallback(
    ({ row }) => (
      <Checkbox
        colorScheme="brand"
        id={row.id}
        isChecked={row.getIsSelected()}
        isDisabled={!row.getCanSelect()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
    [],
  )

  const columns = [
    ...(selectedWarranties !== undefined
      ? [
          columnHelper.display({
            id: 'select',
            header: selectionHeader,
            cell: selectionCell,
          }),
        ]
      : []),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => <DataTableHeader>Name</DataTableHeader>,
      cell: (info) => <DataTableCell>{info.getValue()}</DataTableCell>,
    }),
    columnHelper.accessor('policyNumber', {
      id: 'policyNumber',
      header: () => <DataTableHeader>Policy Number</DataTableHeader>,
      cell: (info) => <DataTableCell>{info.getValue()}</DataTableCell>,
    }),
    columnHelper.accessor('renewalDate', {
      id: 'renewalDate',
      header: () => <DataTableHeader>Renewal Date</DataTableHeader>,
      cell: (info) => <DataTableCell>{info.getValue()}</DataTableCell>,
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: () => <DataTableHeader>Status</DataTableHeader>,
      cell: (info) => (
        <DataTableCell>
          <Text color={info.getValue()?.colour}>
            {info.getValue()?.title ?? '-'}
          </Text>
        </DataTableCell>
      ),
    }),
    ...(showGarage
      ? []
      : [
          columnHelper.accessor('garage', {
            id: 'garage',
            header: () => <DataTableHeader>Garage</DataTableHeader>,
            cell: (info) => <DataTableCell>{info.getValue()}</DataTableCell>,
          }),
        ]),
    columnHelper.accessor('agent', {
      id: 'agent',
      header: () => <DataTableHeader>Agent</DataTableHeader>,
      cell: (info) => <DataTableCell>{info.getValue()}</DataTableCell>,
    }),
    columnHelper.display({
      id: 'actions',
      cell: useCallback(
        (cell) => (
          <Actions
            actions={[
              {
                title: 'View',
                icon: MdVisibility,
                route: reverse(routes.warranties.detail, {
                  warrantyId: cell.row.original.id,
                }),
              },
            ]}
          />
        ),
        [],
      ),
    }),
  ]

  return (
    <DataTable
      meta={data?.meta}
      rows={rows}
      columns={columns as any}
      loading={loading}
      actions={actions}
      getRowID={(row: WarrantyRow) => row.id}
      selectedRows={selectedRows}
      onRowSelectionChange={onRowSelectionChange}
    />
  )
}

export default WarrantiesTable
