import { useDispatch, useSelector } from 'react-redux'
import { getProduct, loadProduct, setProduct } from '../store/products'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { type PaginatedResponse, type PaginationMeta, Product } from '../types'
import { useParams } from 'react-router-dom'
import { AppDispatch } from '../store'
import { AvailableServices, ServiceContext } from '../components/Services'
import { useQueryParams } from './queryParams'

export type ProductTableDataQuery = {}

export type ProductTableData = {
  products: PaginatedResponse<Product, PaginationMeta> | null
  loadProducts: (query?: ProductTableDataQuery) => void
  loadingProducts: boolean
}

export const useProductTableData = (
  query: ProductTableDataQuery = {},
): ProductTableData => {
  const services: AvailableServices = useContext(ServiceContext)
  const { params } = useQueryParams()
  const queryRef = useRef<string>('')
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<PaginatedResponse<
    Product,
    PaginationMeta
  > | null>(null)

  const loadData = useCallback(
    (_: ProductTableDataQuery = {}) => {
      if (params !== null) {
        setLoading(true)
        services.product
          .list({
            ...params,
          })
          .then((response: PaginatedResponse<Product, PaginationMeta>) => {
            setLoading(false)
            setData(response)
          })
          .catch(() => {
            setLoading(false)
            setData(null)
          })
      }
    },
    [services, params],
  )

  useEffect(() => {
    const serialised = JSON.stringify({ ...params, ...query })
    if (serialised !== queryRef.current) {
      queryRef.current = serialised
      loadData(query)
    }
  }, [loadData, params, query])

  return {
    products: data,
    loadProducts: loadData,
    loadingProducts: loading,
  }
}

type ProductParams = {
  productId?: string
}

export const useProduct = () => {
  const { productId } = useParams<ProductParams>()
  const { product, loading, exception } = useSelector(getProduct)
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    if (productId && productId !== `${product?.id}`) {
      dispatch(loadProduct(parseInt(productId)))
    }
  }, [product, productId, dispatch])

  const updateProduct = useCallback(
    (product: Product | null) => {
      dispatch(setProduct(product))
    },
    [dispatch],
  )

  return {
    product,
    loading,
    exception,
    onUpdate: updateProduct,
  }
}
