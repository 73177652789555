import React, { useCallback, useContext, useState } from 'react'
import { Avatar, Box, Flex, Text, useColorModeValue } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { getUser } from '../../../../store/auth'
import { MANAGEMENT, TELESALES, userCanAccess } from '../../../../utils'
import UserMenu from '../../molecules/users/UserMenu'
import { useWarranty } from '../../../../hooks'
import { ServiceContext } from '../../../../components/Services'
import { User } from '../../../../types'

const WarrantyAgent: React.FunctionComponent = () => {
  const services = useContext(ServiceContext)
  const { warranty, onUpdate } = useWarranty()
  const [assigning, setAssigning] = useState(false)
  const user = useSelector(getUser)

  const selectUser = useCallback(
    (user: User | null) => {
      setAssigning(true)
      services.warranty
        .assign([warranty], user)
        .then(() => {
          setAssigning(false)
          onUpdate({ ...warranty, user })
        })
        .catch(() => setAssigning(false))
    },
    [warranty, onUpdate, services],
  )

  const textColour = useColorModeValue('secondaryGray.900', 'white')
  const avatarColour = useColorModeValue('white', 'navy.800')
  const brandColour = useColorModeValue('brand.500', 'white')
  return (
    <Flex alignItems="center" direction="column" gap="1rem" paddingY="2rem">
      <Flex alignItems="center" gap="1rem" justifyContent="center">
        <Avatar
          color={avatarColour}
          name={warranty.user?.full_name}
          bg={brandColour}
          size="sm"
          width="40px"
          height="40px"
        />
        <Box>
          <Text color="secondaryGray.600" fontSize="md" fontWeight="500">
            Assigned Agent
          </Text>
          <Flex align="center">
            <Text color={textColour} fontSize="lg" fontWeight="700">
              {warranty.user?.full_name ?? 'Not Yet Assigned'}
            </Text>
          </Flex>
        </Box>
      </Flex>
      {userCanAccess(user, MANAGEMENT) ? (
        <UserMenu
          role={TELESALES}
          label={warranty.user ? 'Re-assign' : 'Assign'}
          selected={warranty.user}
          onSelect={selectUser}
          disabled={assigning}
        />
      ) : null}
    </Flex>
  )
}

export default WarrantyAgent
