import type {
  PaginatedResponse,
  PaginationMeta,
  PaginationMetaResponse,
  QueryParams,
  TableRequest,
} from '../types'

export const mapPaginatedResponse = <I, O>(
  response: PaginatedResponse<I, PaginationMetaResponse>,
  mapper: (entity: any) => any,
): PaginatedResponse<O, PaginationMeta> => {
  const { data, meta } = response
  return {
    data: data.map(mapper),
    meta: {
      length: meta.per_page,
      currentPage: meta.current_page,
      lastPage: meta.last_page,
      nextPage:
        meta.current_page < meta.last_page ? meta.current_page + 1 : null,
      previousPage: meta.current_page > 1 ? meta.current_page - 1 : null,
      from: meta.from,
      to: meta.to,
      total: meta.total,
    } as PaginationMeta,
  }
}

export const mapTableQuery = (data: QueryParams): TableRequest => {
  return {
    page: data.page ?? 1,
    ...(data.length ? { length: data.length } : {}),
    filter: {
      ...(data.search ? { search: data.search } : {}),
      ...(data.garage ? { garage: `${data.garage}` } : {}),
      ...(data.role ? { role: data.role.slug } : {}),
      ...(data.product != null ? { product: `${data.product}` } : {}),
      ...(data.user != null ? { user: `${data.user}` } : {}),
      ...(data.archive ? { archive: 'only' } : {}),
    },
  }
}
