import React, { useCallback, useContext, useState } from 'react'
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { getUser } from '../../../../store/auth'
import { MANAGEMENT, userCanAccess } from '../../../../utils'
import { useWarranty } from '../../../../hooks'
import { ServiceContext } from '../../../../components/Services'
import { Garage } from '../../../../types'
import GarageMenu from '../../molecules/garages/GarageMenu'

const WarrantyGarage: React.FunctionComponent = () => {
  const services = useContext(ServiceContext)
  const { warranty, onUpdate } = useWarranty()
  const [updating, setUpdating] = useState(false)
  const user = useSelector(getUser)

  const selectGarage = useCallback(
    (garage: Garage | null) => {
      setUpdating(true)
      services.warranty
        .update(warranty.id, { garage_id: garage.id })
        .then(() => {
          setUpdating(false)
          onUpdate({ ...warranty, garage })
        })
        .catch(() => setUpdating(false))
    },
    [warranty, onUpdate, services],
  )

  const textColour = useColorModeValue('secondaryGray.900', 'white')
  return (
    <Flex alignItems="center" direction="column" gap="1rem" paddingY="2rem">
      <Flex alignItems="center" gap="1rem" justifyContent="center">
        <Box>
          <Text
            color="secondaryGray.600"
            fontSize="md"
            fontWeight="500"
            textAlign="center"
          >
            Garage
          </Text>
          <Flex align="center" justifyContent="center">
            <Text
              color={textColour}
              fontSize="lg"
              fontWeight="700"
              textAlign="center"
            >
              {warranty.garage?.name ?? 'None Associated'}
            </Text>
          </Flex>
        </Box>
      </Flex>
      {userCanAccess(user, MANAGEMENT) ? (
        <GarageMenu
          label="Update"
          selected={warranty.garage}
          onSelect={selectGarage}
          disabled={updating}
        />
      ) : null}
    </Flex>
  )
}

export default WarrantyGarage
