import qs from 'qs'
import { API } from './API'
import type {
  APIException,
  Garage,
  GarageResponse,
  GarageUpdate,
  PaginatedResponse,
  PaginationMeta,
  PaginationMetaResponse,
  SingularResponse,
  QueryParams,
} from '../types'
import {
  mapGarage,
  mapGarageUpdate,
  mapPaginatedResponse,
  mapTableQuery,
} from '../mappers'

export class GarageService {
  public async list(
    params: QueryParams,
  ): Promise<PaginatedResponse<Garage, PaginationMeta>> {
    return (await API.getConnection())
      .get('garages', {
        params: mapTableQuery(params),
        paramsSerializer: (params: any) => qs.stringify(params),
      })
      .then(
        (response: {
          data: PaginatedResponse<GarageResponse, PaginationMetaResponse>
        }) =>
          mapPaginatedResponse<GarageResponse, Garage>(
            response.data,
            mapGarage,
          ),
      )
      .catch((error: APIException | null) => API.handleError(error))
  }

  public async single(id: number, archive = false): Promise<Garage> {
    return (await API.getConnection())
      .get(`garages/${id}`, {
        params: archive ? { filter: { archive: 'only' } } : {},
        paramsSerializer: (params: any) => qs.stringify(params),
      })
      .then((response: SingularResponse<GarageResponse>) =>
        mapGarage(response.data.data),
      )
      .catch((error: APIException | null) => API.handleError(error))
  }

  public async create(data: GarageUpdate): Promise<Garage> {
    return (await API.getConnection())
      .post('garages', mapGarageUpdate(data))
      .then((response: SingularResponse<GarageResponse>) =>
        mapGarage(response.data.data),
      )
      .catch((error: APIException | null) => API.handleError(error))
  }

  public async update(id: number, data: GarageUpdate): Promise<Garage> {
    return (await API.getConnection())
      .put(`garages/${id}`, mapGarageUpdate(data))
      .then((response: SingularResponse<GarageResponse>) =>
        mapGarage(response.data.data),
      )
      .catch((error: APIException | null) => API.handleError(error))
  }
}
