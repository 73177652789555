import * as React from 'react'
import AdminTemplate from '../../templates/AdminTemplate'
import routes from '../../../../routes'
import { Box, Grid, GridItem, Text, useColorModeValue } from '@chakra-ui/react'
import DetailHeader from '../../molecules/shared/DetailHeader'
import Card from '../../../../horizon/components/card/Card'
import ProductDetails from '../../organisms/products/ProductDetails'
import { useProduct, useWarrantyTableData } from '../../../../hooks'
import TariffsTable from '../../organisms/products/TariffsTable'
import WarrantiesTable from '../../organisms/warranties/WarrantiesTable'
import Tabs from '../../molecules/shared/Tabs'

const ProductPage: React.FunctionComponent = () => {
  const { product } = useProduct()

  const { warranties, loadingWarranties } = useWarrantyTableData(
    { product },
    !!product,
  )

  const textPrimary = useColorModeValue('secondaryGray.900', 'white')
  return (
    <AdminTemplate
      breadcrumbs={[
        { label: 'Warranties 2000', link: routes.dashboard },
        { label: 'Administration' },
        { label: 'Products', link: routes.products.index },
        ...(product ? [{ label: product.name }] : []),
      ]}
      title={product ? product.name : 'Loading...'}
    >
      {product ? (
        <Box>
          <Grid marginBottom="20px" templateColumns="repeat(3, 1fr)" gap="20px">
            <GridItem colSpan={2}>
              <DetailHeader
                title={product.name}
                createdAt={product.created_at}
                updatedAt={product.updated_at}
              />
            </GridItem>
            <GridItem />
            <GridItem>
              <ProductDetails />
            </GridItem>

            <GridItem colSpan={3}>
              <Tabs
                tabs={[
                  {
                    title: 'Tariffs',
                    slug: 'tariffs',
                    onRender: () => (
                      <Card
                        padding="1rem"
                        display="flex"
                        flexDirection="column"
                        gap="1rem"
                      >
                        <Text
                          fontSize="xl"
                          color={textPrimary}
                          fontWeight="bold"
                          paddingX="0.5rem"
                        >
                          Tariffs
                        </Text>
                        <TariffsTable product={product} loading={false} />
                      </Card>
                    ),
                  },
                  {
                    title: 'Warranties',
                    slug: 'warranties',
                    onRender: () => (
                      <Card
                        padding="1rem"
                        display="flex"
                        flexDirection="column"
                        gap="1rem"
                      >
                        <Text
                          fontSize="xl"
                          color={textPrimary}
                          fontWeight="bold"
                          paddingX="0.5rem"
                        >
                          Warranties
                        </Text>
                        <WarrantiesTable
                          data={warranties}
                          loading={loadingWarranties}
                        />
                      </Card>
                    ),
                  },
                ]}
              />
            </GridItem>
          </Grid>
        </Box>
      ) : null}
    </AdminTemplate>
  )
}

export default ProductPage
