import React, { useCallback, useContext, useState } from 'react'
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react'
import { useWarranty } from '../../../../hooks'
import { ServiceContext } from '../../../../components/Services'
import { WarrantyStatus as Status } from '../../../../types'
import WarrantyStatusMenu from '../../molecules/warranties/WarrantyStatusMenu'

const WarrantyStatus: React.FunctionComponent = () => {
  const services = useContext(ServiceContext)
  const { warranty, onUpdate } = useWarranty()
  const [updating, setUpdating] = useState(false)

  const selectStatus = useCallback(
    (status: Status) => {
      setUpdating(true)
      services.warranty
        .update(warranty.id, { status })
        .then(() => {
          setUpdating(false)
          onUpdate({ ...warranty, status })
        })
        .catch(() => setUpdating(false))
    },
    [warranty, onUpdate, services],
  )

  const textColour = useColorModeValue('secondaryGray.900', 'white')
  return (
    <Flex alignItems="center" direction="column" gap="1rem" paddingY="2rem">
      <Flex alignItems="center" gap="1rem" justifyContent="center">
        <Box>
          <Text
            color="secondaryGray.600"
            fontSize="md"
            fontWeight="500"
            textAlign="center"
          >
            Status
          </Text>
          <Flex align="center" justifyContent="center">
            <Text
              color={warranty.status?.colour ?? textColour}
              fontSize="lg"
              fontWeight="700"
              textAlign="center"
            >
              {warranty.status?.title ?? '-'}
            </Text>
          </Flex>
        </Box>
      </Flex>
      <WarrantyStatusMenu
        label="Update"
        selected={warranty.status}
        onSelect={selectStatus}
        disabled={updating}
      />
    </Flex>
  )
}

export default WarrantyStatus
