import * as React from 'react'
import { useCallback, useContext, useEffect, useState } from 'react'
import AdminTemplate from '../../templates/AdminTemplate'
import routes from '../../../../routes'
import WarrantiesTable from '../../organisms/warranties/WarrantiesTable'
import UserMenu from '../../molecules/users/UserMenu'
import { Garage, User, Warranty } from '../../../../types'
import { TELESALES } from '../../../../utils'
import { Flex, useToast } from '@chakra-ui/react'
import {
  useGarages,
  useQueryParams,
  useUsersByRole,
  useWarrantyTableData,
} from '../../../../hooks'
import { ServiceContext } from '../../../../components/Services'
import { useDispatch } from 'react-redux'
import { setWarranty } from '../../../../store/warranties'
import GarageMenu from '../../molecules/garages/GarageMenu'

const WarrantiesAssignPage: React.FunctionComponent = () => {
  const services = useContext(ServiceContext)
  const toast = useToast()
  const dispatch = useDispatch()
  const { users } = useUsersByRole(TELESALES)
  const { garages } = useGarages()
  const [user, setUser] = useState<User | null>(null)
  const [garage, setGarage] = useState<Garage | null>(null)
  const [unassigned, setUnassigned] = useState<boolean>(false)
  const [selection, setSelection] = useState<Warranty[]>([])
  const [assigning, setAssigning] = useState<boolean>(false)
  const { warranties, loadWarranties, loadingWarranties } =
    useWarrantyTableData()
  const { params, updateParams } = useQueryParams()

  useEffect(() => {
    if (params) {
      if (params.user === undefined) {
        setUnassigned(false)
        setUser(null)
      } else if (params.user === 0) {
        setUnassigned(true)
        setUser(null)
      } else {
        setUnassigned(false)
        setUser(users.find(({ id }) => id === params.user) ?? null)
      }
      if (params.garage === undefined) {
        setGarage(null)
      } else {
        setGarage(garages.find(({ id }) => id === params.garage) ?? null)
      }
    }
  }, [params, users, garages])

  const toggleUnassigned = useCallback(() => {
    updateParams(unassigned ? { user: undefined } : { user: 0 })
  }, [updateParams, unassigned])

  const selectUser = useCallback(
    (user: User | null) => {
      updateParams(user ? { user: user.id } : {})
    },
    [updateParams],
  )

  const selectGarage = useCallback(
    (garage: Garage | null) => {
      updateParams({ garage: garage?.id })
    },
    [updateParams],
  )

  const assign = useCallback(
    (user: User | null) => {
      setAssigning(true)
      services.warranty
        .assign(selection, user)
        .then((assigned) => {
          setAssigning(false)
          setSelection([])
          loadWarranties()
          dispatch(setWarranty(null))
          toast({
            title: `Warrant${assigned === 1 ? 'y' : 'ies'} Assigned`,
            description: `The ${assigned} warrant${assigned === 1 ? 'y' : 'ies'} you selected have been ${user ? `assigned to ${user.full_name}` : 'unassigned'}`,
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        })
        .catch(() => {
          setAssigning(false)
          toast({
            title: 'Error',
            description:
              'Sorry there has been a problem assigning the warranties you selected',
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        })
    },
    [services, toast, dispatch, loadWarranties, selection],
  )

  return (
    <AdminTemplate
      breadcrumbs={[
        { label: 'Warranties 2000', link: routes.dashboard },
        { label: 'Warranties', link: routes.warranties.index },
        { label: 'Assign', link: routes.warranties.index },
      ]}
      title="Assign Warranties"
    >
      <WarrantiesTable
        data={warranties}
        loading={loadingWarranties || assigning}
        actions={
          <Flex justify="flex-end" flex={1} gap="1rem">
            {selection.length > 0 ? (
              <UserMenu
                selected={null}
                label={`Assign ${selection.length} Warrant${selection.length === 1 ? 'y' : 'ies'}`}
                onSelect={assign}
                role={TELESALES}
                additionalOptions={{
                  before: [
                    {
                      label: 'Unassigned',
                      selected: false,
                      onSelect: () => assign(null),
                    },
                  ],
                }}
              />
            ) : null}
            <GarageMenu
              selected={garage}
              label={garage ? garage.name : 'Filter by Garage'}
              onSelect={selectGarage}
            />
            <UserMenu
              selected={user}
              label={
                unassigned
                  ? 'Unassigned'
                  : user
                    ? user.full_name
                    : 'Filter by Agent'
              }
              onSelect={selectUser}
              role={TELESALES}
              additionalOptions={{
                before: [
                  {
                    label: 'Unassigned',
                    selected: unassigned,
                    onSelect: toggleUnassigned,
                  },
                ],
              }}
            />
          </Flex>
        }
        selectedWarranties={selection}
        onChangeSelection={setSelection}
      />
    </AdminTemplate>
  )
}

export default WarrantiesAssignPage
