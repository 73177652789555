import { AvailableServices } from '../components/Services'

export class ServiceProvider {
  private static services: AvailableServices

  static getServices(): AvailableServices {
    return ServiceProvider.services
  }

  static setServices(services: AvailableServices) {
    ServiceProvider.services = services
  }
}
