import * as React from 'react'
import AdminTemplate from '../../templates/AdminTemplate'
import routes from '../../../../routes'
import WarrantiesTable from '../../organisms/warranties/WarrantiesTable'
import TableButtons from '../../molecules/shared/TableButtons'
import { MdSupervisedUserCircle, MdUploadFile } from 'react-icons/md'
import { MANAGEMENT, userCanAccess } from '../../../../utils'
import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { getUser } from '../../../../store/auth'
import { useWarrantyTableData } from '../../../../hooks'

const WarrantiesPage: React.FunctionComponent = () => {
  const currentUser = useSelector(getUser)
  const { warranties, loadingWarranties } = useWarrantyTableData()

  return (
    <AdminTemplate
      breadcrumbs={[
        { label: 'Warranties 2000', link: routes.dashboard },
        { label: 'Warranties' },
      ]}
      title="Warranties"
    >
      <WarrantiesTable
        data={warranties}
        loading={loadingWarranties}
        actions={
          userCanAccess(currentUser, MANAGEMENT) ? (
            <TableButtons
              buttons={[
                {
                  title: 'Assign',
                  icon: MdSupervisedUserCircle,
                  route: routes.warranties.assign,
                },
                {
                  title: 'Import CSV',
                  icon: MdUploadFile,
                  route: routes.warranties.import,
                },
              ]}
            />
          ) : (
            <Fragment />
          )
        }
      />
    </AdminTemplate>
  )
}

export default WarrantiesPage
