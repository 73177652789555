import type {
  Warranty,
  WarrantyResponse,
  WarrantyUpdate,
  WarrantyUpdateData,
} from '../types'
import { format } from 'date-fns'
import {
  DATABASE_DATE_FORMAT,
  DATABASE_DATETIME_FORMAT,
  WARRANTY_STATUSES,
} from '../utils'
import { mapGarage } from './garages'
import { mapUser } from './auth'

export const mapWarranty = (data: WarrantyResponse): Warranty => {
  return {
    ...data,
    status: data.status
      ? WARRANTY_STATUSES.find(({ key }) => key === data.status)
      : null,
    user: data.user ? mapUser(data.user) : null,
    garage: data.garage ? mapGarage(data.garage) : null,
    full_name: [data.title, data.first_name, data.last_name]
      .filter((p) => !!p)
      .join(' '),
    expires_on: new Date(data.expires_on),
    purchased_on: new Date(data.purchased_on),
    rejected_at: data.rejected_at ? new Date(data.rejected_at) : null,
    created_at: new Date(data.created_at),
    updated_at: new Date(data.updated_at),
  }
}

export const mapWarrantyUpdate = (data: WarrantyUpdate): WarrantyUpdateData => {
  const { status, expires_on, purchased_on, rejected_at, ...attrs } = data
  return {
    ...attrs,
    ...(status ? { status: status.key } : {}),
    ...(expires_on
      ? { expires_on: format(expires_on, DATABASE_DATE_FORMAT) }
      : {}),
    ...(purchased_on
      ? { purchased_on: format(purchased_on, DATABASE_DATE_FORMAT) }
      : {}),
    ...(rejected_at
      ? { rejected_at: format(rejected_at, DATABASE_DATETIME_FORMAT) }
      : {}),
  }
}
