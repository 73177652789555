import {
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  useColorModeValue,
} from '@chakra-ui/react'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { BaseField, FieldProps } from './BaseField'
import { RiEyeCloseLine } from 'react-icons/ri'
import { MdOutlineRemoveRedEye } from 'react-icons/md'
import { getFieldOptions } from '../../../../utils'

export function PasswordField<T extends object>(props: FieldProps) {
  const { field, showPasswords, onTogglePasswords, isDisabled } = props
  const { register } = useFormContext<T>()

  const textPrimary = useColorModeValue('secondaryGray.900', 'white')
  const textSecondary = 'secondaryGray.600'
  return (
    <BaseField {...props}>
      <InputGroup size="md">
        <Input
          type={showPasswords ? 'text' : 'password'}
          fontSize="sm"
          fontWeight="500"
          size="lg"
          color={textPrimary}
          {...register(field.name as any, getFieldOptions(field))}
        />
        <InputRightElement display="flex" alignItems="center" mt="4px">
          <Icon
            color={textSecondary}
            _hover={{ cursor: 'pointer' }}
            as={showPasswords ? RiEyeCloseLine : MdOutlineRemoveRedEye}
            onClick={() =>
              isDisabled || !onTogglePasswords
                ? null
                : onTogglePasswords(!showPasswords)
            }
          />
        </InputRightElement>
      </InputGroup>
    </BaseField>
  )
}
