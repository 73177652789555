import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import React, { useCallback, useMemo } from 'react'
import { Role, User } from '../../../../types'
import { useUsersByRole } from '../../../../hooks'

export type AdditionalOptions = {
  before?: Array<{ label: string; selected: boolean; onSelect: () => void }>
  after?: Array<{ label: string; selected: boolean; onSelect: () => void }>
}

const UserMenu: React.FunctionComponent<{
  role?: Role
  label: string
  selected: User | null
  onSelect: (user: User | null) => void
  disabled?: boolean
  additionalOptions?: AdditionalOptions
}> = ({
  role,
  label,
  selected,
  onSelect,
  disabled = false,
  additionalOptions,
}) => {
  const { users, loading: loadingUsers } = useUsersByRole(role)

  const isDisabled = useMemo(
    () => disabled || loadingUsers,
    [disabled, loadingUsers],
  )

  const selectUser = useCallback(
    (user: User) => {
      onSelect(selected?.id === user.id ? null : user)
    },
    [selected, onSelect],
  )

  return users.length > 0 ? (
    <Menu>
      <MenuButton
        as={Button}
        variant="brand"
        rightIcon={<ChevronDownIcon />}
        disabled={isDisabled}
      >
        {label}
      </MenuButton>
      <MenuList>
        {(additionalOptions?.before ?? []).map(
          ({ label, selected, onSelect }, index) => (
            <MenuItem
              key={`before-${index}`}
              onClick={() => onSelect()}
              fontStyle="italic"
            >
              {selected ? <strong>{label}</strong> : label}
            </MenuItem>
          ),
        )}
        {users.map((user, index) => (
          <MenuItem key={`user-${index}`} onClick={() => selectUser(user)}>
            {user.id === selected?.id ? (
              <strong>{user.full_name}</strong>
            ) : (
              user.full_name
            )}
          </MenuItem>
        ))}
        {(additionalOptions?.after ?? []).map(
          ({ label, selected, onSelect }, index) => (
            <MenuItem
              key={`after-${index}`}
              onClick={() => onSelect()}
              fontStyle="italic"
            >
              {selected ? <strong>{label}</strong> : label}
            </MenuItem>
          ),
        )}
      </MenuList>
    </Menu>
  ) : null
}

export default UserMenu
