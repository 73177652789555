import {
  Box,
  Flex,
  Icon,
  Portal,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import { SidebarContext } from '../../../horizon/contexts/SidebarContext'
import React, { PropsWithChildren, useState } from 'react'
import {
  MdAdminPanelSettings,
  MdArchive,
  MdGarage,
  MdHome,
  MdOutlineCarCrash,
} from 'react-icons/md'
import Header from '../molecules/shared/Header'
import Sidebar from '../molecules/shared/Sidebar'
import Footer from '../molecules/shared/Footer'
import routes from '../../../routes'
import { useSelector } from 'react-redux'
import { MANAGEMENT, userCanAccess } from '../../../utils'
import { getUser } from '../../../store/auth'

const AdminTemplate: React.FunctionComponent<
  PropsWithChildren<{
    title: string
    breadcrumbs: Array<{ link?: string; label: string }>
  }>
> = ({ title, breadcrumbs, children }) => {
  const user = useSelector(getUser)
  const [fixed] = useState(false)
  const [toggleSidebar, setToggleSidebar] = useState(false)
  const [hovered, setHovered] = useState(false)
  const backgroundColour = useColorModeValue('background.100', 'background.900')
  const { onOpen } = useDisclosure()
  return (
    <Box bg={backgroundColour} h="100vh" w="100vw">
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Sidebar
          hovered={hovered}
          setHovered={setHovered}
          routes={[
            {
              name: 'Dashboard',
              path: routes.dashboard,
              icon: (
                <Icon as={MdHome} width="20px" height="20px" color="inherit" />
              ),
              collapse: false,
            },
            {
              name: 'Warranties',
              path: routes.warranties.index,
              icon: (
                <Icon
                  as={MdOutlineCarCrash}
                  width="20px"
                  height="20px"
                  color="inherit"
                />
              ),
            },
            ...(userCanAccess(user, MANAGEMENT)
              ? [
                  {
                    name: 'Garages',
                    path: routes.garages.index,
                    icon: (
                      <Icon
                        as={MdGarage}
                        width="20px"
                        height="20px"
                        color="inherit"
                      />
                    ),
                  },
                  {
                    name: 'Administration',
                    path: routes.admin.index,
                    icon: (
                      <Icon
                        as={MdAdminPanelSettings}
                        width="20px"
                        height="20px"
                        color="inherit"
                      />
                    ),
                    collapse: true,
                    items: [
                      {
                        name: 'Products',
                        path: routes.products.index,
                      },
                      {
                        name: 'Users',
                        path: routes.users.index,
                      },
                    ],
                  },
                  {
                    name: 'Archive',
                    path: routes.archive.users,
                    icon: (
                      <Icon
                        as={MdArchive}
                        width="20px"
                        height="20px"
                        color="inherit"
                      />
                    ),
                    collapse: true,
                    items: [
                      {
                        name: 'Users',
                        path: routes.archive.users,
                      },
                    ],
                  },
                ]
              : []),
          ]}
        />
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          w={{ base: '100%', xl: 'calc( 100% - 290px )' }}
          maxWidth={{ base: '100%', xl: 'calc( 100% - 290px )' }}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Portal>
            <Box>
              <Header
                hovered={hovered}
                onOpen={onOpen}
                title={title}
                breadcrumbs={breadcrumbs}
                fixed={fixed}
              />
            </Box>
          </Portal>

          <Box
            mx="auto"
            p={{ base: '20px', md: '30px' }}
            pe="20px"
            minH="100vh"
            pt="50px"
            display="flex"
            flexDirection="column"
          >
            <Flex
              direction="column"
              pt={{ base: '130px', md: '80px', xl: '80px' }}
              flex={1}
            >
              <Box flex={1}>{children}</Box>
              <Box>
                <Footer />
              </Box>
            </Flex>
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  )
}

export default AdminTemplate
