import {
  Avatar,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { SearchBar } from '../../../../horizon/components/navbar/searchBar/SearchBar'
import React, { useCallback, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import routes from '../../../../routes'
import { ServiceContext } from '../../../../components/Services'
import { getUser } from '../../../../store/auth'

const Search: React.FunctionComponent<{ secondary: boolean }> = ({
  secondary,
}) => {
  const user = useSelector(getUser)
  const services = useContext(ServiceContext)

  const handleLogout = useCallback(() => {
    services.auth
      .logout()
      .then(() => {})
      .catch(() => {})
  }, [services])

  const menuBackgroundColour = useColorModeValue('white', 'navy.800')
  const brandColour = useColorModeValue('brand.500', 'white')
  const textColour = useColorModeValue('secondaryGray.900', 'white')
  const borderColour = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)')
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  )
  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      bg={menuBackgroundColour}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p="10px"
      borderRadius="999px"
      boxShadow={shadow}
    >
      <SearchBar
        mb={() => {
          if (secondary) {
            return { base: '10px', md: 'unset' }
          }
          return 'unset'
        }}
        me="10px"
        borderRadius="20px"
      />

      <Menu>
        <MenuButton p="0px">
          <Avatar
            _hover={{ cursor: 'pointer' }}
            color={menuBackgroundColour}
            name={user.full_name}
            bg={brandColour}
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBackgroundColour}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColour}
              fontSize="sm"
              fontWeight="700"
              color={textColour}
            >
              {user['first_name']} {user['last_name']}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              borderRadius="8px"
              px="14px"
              as={NavLink}
              to={routes.profile}
            >
              <Text fontSize="sm">Profile Settings</Text>
            </MenuItem>
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              color="red.400"
              borderRadius="8px"
              px="14px"
              onClick={handleLogout}
            >
              <Text fontSize="sm">Log out</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  )
}

export default Search
