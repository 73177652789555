import * as React from 'react'
import { Text, useColorModeValue } from '@chakra-ui/react'

const DataTableCell: React.FunctionComponent = ({ children }) => {
  const textColour = useColorModeValue('navy.700', 'white')
  return (
    <Text color={textColour} fontSize="md" fontWeight="500">
      {children}
    </Text>
  )
}

export default DataTableCell
