import { FormConfig, FormFieldType } from '../types'

export type GarageDetailsFields = {
  name: string
}

export const garageDetails: FormConfig = {
  fields: [
    {
      name: 'name',
      label: 'Name',
      requiredError: 'Please enter a name for this garage',
      type: FormFieldType.Text,
    },
  ],
}
