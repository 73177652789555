import React, { useCallback, useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { MdOutlineRemoveRedEye } from 'react-icons/md'
import { RiEyeCloseLine } from 'react-icons/ri'
import { useForm } from 'react-hook-form'
import routes from '../../../routes'
import AccessTemplate from '../templates/AccessTemplate'
import { ServiceContext } from '../../../components/Services'
import { useDispatch } from 'react-redux'
import { setUser } from '../../../store/auth'
import { User } from '../../../types'

type Inputs = {
  email: string
  password: string
}

const Login: React.FunctionComponent = () => {
  const services = useContext(ServiceContext)
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()
  const textColor = useColorModeValue('purple.900', 'white')
  const textColorSecondary = 'gray.400'
  const textColorBrand = useColorModeValue('brand.900', 'white')

  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const onSubmit = useCallback(
    (input: Inputs) => {
      const failed = () => {
        setLoading(false)
        setError(
          'Sorry, the details you have entered do not match a valid user',
        )
      }
      setError('')
      setLoading(true)
      services.auth
        .login(input.email, input.password)
        .then(() => {
          services.auth
            .me()
            .then((user: User) => {
              dispatch(setUser(user))
              setLoading(false)
            })
            .catch(failed)
        })
        .catch(failed)
    },
    [services, dispatch],
  )

  return (
    <AccessTemplate>
      <Box me="auto">
        <Heading color={textColor} fontSize="36px" mb="10px">
          Sign In
        </Heading>
        <Text
          marginBottom="1rem"
          color={textColorSecondary}
          fontWeight="400"
          fontSize="md"
        >
          Enter your email and password to sign in
        </Text>
      </Box>
      <Flex
        marginX={{ base: 'auto', lg: '0px' }}
        flexDirection="column"
        justifyContent="center"
        paddingX={{ base: '20px', md: '0px' }}
        width="100%"
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate={true}
      >
        <Flex
          direction="column"
          marginX={{ base: 'auto', lg: 'unset' }}
          marginBottom={{ base: '20px', md: 'auto' }}
          width="100%"
        >
          {error ? (
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          ) : null}
          <FormControl
            marginY="0.5rem"
            isInvalid={!!errors.email?.type}
            isDisabled={loading}
          >
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Email *
            </FormLabel>
            <Input
              type="email"
              fontSize="sm"
              fontWeight="500"
              size="lg"
              color={textColor}
              {...register('email', { required: true })}
            />
            {errors.email?.type === 'required' ? (
              <FormErrorMessage>Please enter an email address</FormErrorMessage>
            ) : null}
          </FormControl>
          <FormControl
            marginY="0.5rem"
            isInvalid={!!errors.password?.type}
            isDisabled={loading}
          >
            <FormLabel
              marginLeft="0.25rem"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              Password *
            </FormLabel>
            <InputGroup size="md">
              <Input
                fontSize="sm"
                size="lg"
                type={show ? 'text' : 'password'}
                color={textColor}
                {...register('password', { required: true })}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: 'pointer' }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={() => (loading ? null : setShow(!show))}
                />
              </InputRightElement>
            </InputGroup>
            {errors.password?.type === 'required' ? (
              <FormErrorMessage>Please enter your password</FormErrorMessage>
            ) : null}
            <Flex alignItems="center" justifyContent="space-between">
              <NavLink to={routes.forgotPassword}>
                <Text
                  color={textColorBrand}
                  fontSize="sm"
                  fontWeight="500"
                  marginTop="1rem"
                >
                  Forgot password?
                </Text>
              </NavLink>
            </Flex>
          </FormControl>
          <Button
            type="submit"
            variant="brand"
            isDisabled={loading}
            fontSize="sm"
            fontWeight="500"
            marginBottom="1rem"
            marginTop="1.5rem"
            paddingY="1.5rem"
            width="100%"
          >
            Sign In
          </Button>
        </Flex>
      </Flex>
    </AccessTemplate>
  )
}

export default Login
