import React, { useCallback, useContext } from 'react'
import { useToast } from '@chakra-ui/react'
import { UseFormSetValue } from 'react-hook-form'
import {
  AvailableServices,
  ServiceContext,
} from '../../../../components/Services'
import FormCard from '../../molecules/shared/FormCard'
import { useSelector } from 'react-redux'
import { getUser } from '../../../../store/auth'
import { MANAGEMENT, userCanAccess } from '../../../../utils'
import { useWarranty } from '../../../../hooks'
import { warrantyVehicle, WarrantyVehicleFields } from '../../../../forms'
import GridForm from '../forms/GridForm'

const WarrantyVehicle: React.FunctionComponent = () => {
  const services: AvailableServices = useContext(ServiceContext)
  const toast = useToast()
  const user = useSelector(getUser)
  const { warranty, onUpdate } = useWarranty()

  const initialise = useCallback(
    (setValue: UseFormSetValue<WarrantyVehicleFields>) => {
      setValue('registration', warranty.vehicle_registration)
      setValue('make', warranty.make)
      setValue('model', warranty.model)
      setValue('mileage', `${warranty.mileage ?? ''}`)
      setValue('price', `${warranty.price ?? ''}`)
    },
    [warranty],
  )

  const renderFields = useCallback(
    (disabled: boolean, readOnly: boolean) => (
      <GridForm
        config={warrantyVehicle}
        isDisabled={disabled}
        isReadOnly={readOnly}
      />
    ),
    [],
  )

  const onSubmit = useCallback(
    (
      input: WarrantyVehicleFields,
      setError: (error: string) => void,
      onLoading: (loading: boolean) => void,
      onSaved: () => void,
    ) => {
      setError('')
      onLoading(true)
      services.warranty
        .update(warranty.id, {
          vehicle_registration: input.registration,
          make: input.make,
          model: input.model,
          mileage: parseInt(input.mileage),
          price: input.price ? parseInt(input.price) : null,
        })
        .then((updated) => {
          onLoading(false)
          onUpdate(updated)
          onSaved()
          toast({
            title: 'Warranty Updated',
            description: `Your changes to ${warranty.full_name}'s vehicle details have been successfully saved`,
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        })
        .catch(() => {
          onLoading(false)
          setError('Sorry, there was a problem saving the details you entered')
        })
    },
    [services, toast, warranty, onUpdate],
  )

  return (
    <FormCard<WarrantyVehicleFields>
      title="Vehicle Details"
      initialise={initialise}
      renderFields={renderFields}
      onSubmit={onSubmit}
      readOnly={!userCanAccess(user, MANAGEMENT)}
    />
  )
}

export default WarrantyVehicle
