import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import type {
  APIException,
  Product,
  ProductsState,
  ProductState,
} from '../types'
import { ServiceProvider } from '../services/ServiceProvider'
import { RootState } from './index'

export const loadProduct = createAsyncThunk(
  'products/loadProduct',
  async (
    id: number,
  ): Promise<{ product?: Product; exception?: APIException | null }> =>
    ServiceProvider.getServices()
      .product.single(id)
      .then((product) => ({
        product,
      }))
      .catch((exception: APIException | null) => ({
        exception,
      })),
)

const slice = createSlice({
  initialState: {
    product: {
      product: null,
      loading: false,
      exception: null,
    },
  } as ProductsState,
  name: 'products',
  reducers: {
    setProduct: (state, { payload }: PayloadAction<Product | null>) => {
      state.product.product = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadProduct.pending, (state) => {
      state.product.product = null
      state.product.loading = true
      state.product.exception = null
    })
    builder.addCase(loadProduct.rejected, (state) => {
      state.product.loading = false
    })
    builder.addCase(
      loadProduct.fulfilled,
      (state, { payload: { product, exception } }) => {
        state.product.loading = false
        state.product.product = product ?? null
        state.product.exception = exception ?? null
      },
    )
  },
})

export const { setProduct } = slice.actions

export default slice.reducer

export const getProduct = (state: RootState): ProductState =>
  state.products.product
