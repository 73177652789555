import * as React from 'react'
import AdminTemplate from '../../templates/AdminTemplate'
import routes from '../../../../routes'
import UsersTable from '../../organisms/users/UsersTable'
import { useUserTableData } from '../../../../hooks'

const UsersPage: React.FunctionComponent = () => {
  const { users, loadingUsers } = useUserTableData()

  return (
    <AdminTemplate
      breadcrumbs={[
        { label: 'Warranties 2000', link: routes.dashboard },
        { label: 'Administration' },
        { label: 'Users' },
      ]}
      title="Users"
    >
      <UsersTable data={users} loading={loadingUsers} />
    </AdminTemplate>
  )
}

export default UsersPage
