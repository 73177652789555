import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import React, { useCallback } from 'react'
import { WarrantyStatus } from '../../../../types'
import { WARRANTY_STATUSES } from '../../../../utils'

const WarrantyStatusMenu: React.FunctionComponent<{
  label: string
  selected: WarrantyStatus | null
  onSelect: (status: WarrantyStatus | null) => void
  disabled?: boolean
}> = ({ label, selected, onSelect, disabled }) => {
  const selectStatus = useCallback(
    (status: WarrantyStatus) => {
      onSelect(status)
    },
    [onSelect],
  )

  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="brand"
        rightIcon={<ChevronDownIcon />}
        disabled={disabled}
      >
        {label}
      </MenuButton>
      <MenuList>
        {WARRANTY_STATUSES.map((status, index) => (
          <MenuItem
            key={`status-${index}`}
            onClick={() => selectStatus(status)}
          >
            {status.key === selected?.key ? (
              <strong>{status.title}</strong>
            ) : (
              status.title
            )}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

export default WarrantyStatusMenu
