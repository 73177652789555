import {
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  useColorModeValue,
} from '@chakra-ui/react'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { BaseField, FieldProps } from './BaseField'
import { getFieldOptions } from '../../../../utils'

export function TextField<T extends object>(props: FieldProps) {
  const { field, isDisabled } = props
  const { register } = useFormContext<T>()

  const textPrimary = useColorModeValue('secondaryGray.900', 'white')
  return (
    <BaseField {...props}>
      <InputGroup>
        {field.leftAddon ? (
          <InputLeftAddon
            height="auto"
            borderBottomLeftRadius="1rem"
            borderTopLeftRadius="1rem"
            opacity={isDisabled ? '0.5' : '1'}
          >
            {field.leftAddon}
          </InputLeftAddon>
        ) : null}
        <Input
          fontSize="sm"
          fontWeight="500"
          size="lg"
          color={textPrimary}
          {...register(field.name as any, getFieldOptions(field))}
        />
        {field.rightAddon ? (
          <InputRightAddon
            height="auto"
            borderBottomRightRadius="1rem"
            borderTopRightRadius="1rem"
            opacity={isDisabled ? '0.5' : '1'}
          >
            {field.rightAddon}
          </InputRightAddon>
        ) : null}
      </InputGroup>
    </BaseField>
  )
}
