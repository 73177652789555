import * as React from 'react'
import { Text } from '@chakra-ui/react'

const DataTableHeader: React.FunctionComponent = ({ children }) => (
  <Text
    justifyContent="space-between"
    align="center"
    fontSize={{ sm: '10px', lg: '12px' }}
    color="gray.400"
  >
    {children}
  </Text>
)

export default DataTableHeader
