import './assets/css/App.css'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import App from './modules/App'
import Services from './components/Services'
import { store } from './store'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Services>
    <Provider store={store}>
      <App />
    </Provider>
  </Services>,
)
