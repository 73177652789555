import * as React from 'react'
import AdminTemplate from '../../templates/AdminTemplate'
import routes from '../../../../routes'
import GaragesTable from '../../organisms/garages/GaragesTable'
import { useGarageTableData } from '../../../../hooks'

const GaragesPage: React.FunctionComponent = () => {
  const { garages, loadingGarages } = useGarageTableData()

  return (
    <AdminTemplate
      breadcrumbs={[
        { label: 'Warranties 2000', link: routes.dashboard },
        { label: 'Garages' },
      ]}
      title="Garages"
    >
      <GaragesTable data={garages} loading={loadingGarages} />
    </AdminTemplate>
  )
}

export default GaragesPage
